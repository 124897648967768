import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { history } from "../../shared/utils/history";
import { ACTION_CONST, ROUTES } from "../../constants";
import { actGetListProjects, actSelectedProject, actSetCurrentContract } from "../../redux/action/user";
import { getCountDown } from "../../shared/utils/helper";
import { helpers } from "../../shared/utils/helpers";
import { useParams } from "react-router";
import { useBlockingUI, useInfoRound, useIsConnected, useLatestBlockNumber, useSelectedProject, useShowModalHelp, useWeb3Utils } from "../../hook/useState";
import { Container } from "react-bootstrap";
import InvestmentModal from "./modals/InvestModal";
import ApproveModal from "./modals/ApproveModal";
import ScheduleTabComponent from "./components/ScheduleTabComponent";
import YourAllocationComponent from "./components/YourAllocationComponent";
import Footer from "../../shared/components/Footer";
import $ from 'jquery';
import moment from 'moment';
import "react-block-ui/style.css";
import './index.css';
import { useActiveWeb3React } from "../../hook";

const ProjectDetailPage = () => {
  const dispatch = useDispatch();

  let params = useParams();
  const [contractAddress, setContractAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [remainingAllocation, setMaxTokenAllocation] = useState(0);
  const [userParticipation, setUserParticipation] = useState(0);
  const [amountPurchased, setAmountPurchased] = useState(0);
  const [countBuy, setCountBuy] = useState(0);
  const [enableJoinProject, setEnableJoinProject] = useState(false);
  // const [enableApprove, setEnableApprove] = useState(false);
  const [projectState, setProjectState] = useState(null);
  const [tier, setTier] = useState("");
  const [roundState, setRoundState] = useState(0);
  const [textRoundState, setTextRoundState] = useState('')
  const [roundTime, setRoundTime] = useState(0);
  const [allocationNumber, setAllocationNumber] = useState(0);
  const [allocationInfo, setAllocationInfo] = useState([])
  const [layoutAllocation, setLayoutAllocation] = useState(1)
  const [yourAllocationVisible, setYourAllocationVisible] = useState(false)

  const [enableApprove, setEnableApprove] = useState(false);
  const showBlockUI = useBlockingUI()


  // const [init, setInit] = useState(true)
  const [walletInfo, setWalletInfo] = useState({
    remainingAllocation: 0,
    tokenBalance: 0,
  })

  useEffect(() => {
    $('body').addClass('no-bg');
    return (() => {
      $('body').removeClass('no-bg');
    })
  }, [])

  useEffect(() => {
    const { contract } = params
    if (contract && typeof contract === "string") {
      setContractAddress(contract);
      dispatch(actSelectedProject(contract));
      dispatch(actSetCurrentContract(contract));
    } else {
      history.push(ROUTES.HOMEPAGE);
      return;
    }
  }, [showBlockUI])


  const { account } = useActiveWeb3React();
  const latestBlock = useLatestBlockNumber();

  const selectedProject = useSelectedProject();

  const showModalHelp = useShowModalHelp();



  const web3Utils = useWeb3Utils();
  const roundInfo = useInfoRound();

  const [closeTime, setCloseTime] = useState(0);
  const [openTime, setOpenTime] = useState(0)
  const [fcfsOpenTime, setFcfsOpenTime] = useState(0)

  //Job interval  
  useEffect(() => {
    if (contractAddress) {
      dispatch(actSelectedProject(contractAddress))
    }

  }, [latestBlock, contractAddress])

  useEffect(() => {
    if (selectedProject) {

      if (selectedProject.closeTime !== closeTime) {

        setCloseTime(selectedProject.closeTime);
      }
      if (selectedProject.openTime != openTime) {

        setOpenTime(selectedProject.openTime)
      }
      if (selectedProject.fcfsOpenTime != fcfsOpenTime) {
        // console.log("fcfsOpenTime==>", selectedProject.fcfsOpenTime);
        setFcfsOpenTime(selectedProject.fcfsOpenTime)
      }

      setYourAllocationVisible(selectedProject.yourAllocationVisible)
      setProjectState(selectedProject)
    }

  }, [selectedProject]);

  useEffect(() => {

    if (openTime > 0) {
      // console.log("call here")
      getCountDown(`idOpenTime-${selectedProject["contract"]}`, openTime * 1000, (job) => {

        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_OPEN,
          data: job
        })

      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }

  }, [openTime])

  useEffect(() => {
    if (closeTime > 0) {

      getCountDown(`idTimeClose-${selectedProject["contract"]}`, closeTime * 1000, (job) => {

        //do smt when start new count down
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_CLOSE,
          data: job
        })
      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }
  }, [closeTime])

  useEffect(() => {
    if (fcfsOpenTime > 0) {
      getCountDown(`idFcfsOpenTime-${selectedProject["contract"]}`, fcfsOpenTime * 1000, (job) => {

        //do smt when start new count down
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_FCFS_TIME,
          data: job
        })
      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }
  }, [fcfsOpenTime])





  useEffect(() => {

    //round time dc chay lai khi call wallet ==> sinh ra nhieu rountime nay
    if (roundTime > 0 && selectedProject) {
      getCountDown(`idRoundTime-${selectedProject["contract"]}`, roundTime * 1000, function start(job) {
        //do smt when countdown expired
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_ROUND,
          data: job
        })

      }, function end(job) {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))
        //do smt when countdown expired
      });
    }

  }, [roundTime, selectedProject])


  //check enable disable approve button
  useEffect(() => {


    if (Number(remainingAllocation) > Number(allocationNumber)) {
      setEnableApprove(true)
    } else {
      setEnableApprove(false)
    }
  }, [allocationNumber, remainingAllocation])


  useEffect(() => {

    if (account && web3Utils && projectState) {
      dispatch(actGetListProjects())
      // console.log("count===>", count++);

      fetchData()

    }
    async function fetchData() {
      const { contract } = params

      if (contract) {
        await getWalletInfo(contract);
        await getAllowance();
        await getYourAllocation(contract)
      }
    }
    // console.log("update==>", latestBlock);

  }, [account, web3Utils, countBuy, contractAddress, projectState, latestBlock]);


  // console.log("projectState==>", projectState);
  const getWalletInfo = async (contract) => {
    if (web3Utils) {
      web3Utils.getInfoWallet(contract).then(

        data => {
          // console.log("getInfoWallet==>", data);
          setWalletInfo({
            remainingAllocation: data.remainingAllocation / 10 ** projectState.decimal,
            tokenBalance: data.tokenBalance / 10 ** projectState.decimal,
            ethBalance: data.ethBalance
          })

          setEthBalance(data.ethBalance);
          setTokenBalance(data.tokenBalance / 10 ** projectState.decimal);

          setUserParticipation(data.userParticipation / 10 ** projectState.decimal);
          setMaxTokenAllocation(data.remainingAllocation / 10 ** projectState.decimal);
          setTier(data.tier);
          setRoundState(data.roundState);
          setTextRoundState(data.roundStateText)


          if (document.getElementById('idTextRoundState')) {
            document.getElementById('idTextRoundState').innerHTML = data.roundStateText;
          }



          if (roundTime != data.roundTimestamp) {
            setRoundTime(data.roundTimestamp);
          }

          if (!(data.roundState === 1 || data.roundState === 3)) {
            // console.log('call here state ')
            setEnableJoinProject(false);
            return;
          }


          if (projectState.state === "C" || projectState.state === "P" || projectState.address === 'TBA') {
            setEnableJoinProject(false)
            return;
          }
          else {
            if (selectedProject.isPrivate) {
              if (data.remainingAllocation === 0) {

                setEnableJoinProject(false)
                return;
              } else {
                setEnableJoinProject(true)
                return;
              }
            } else {
              setEnableJoinProject(true)
              setMaxTokenAllocation(projectState.maxSingleParticipationAllocated)
              // remainingAllocation = maxSingleParticipationAllocated
            }
          }

        }
      ).catch(err => {

        console.log(err);
      })
    }

  }


  const getAllowance = async () => {
    web3Utils.getAllowance(selectedProject.tokenAddress, selectedProject.contract).then(data => {
      setAllocationNumber(data)
    }).catch(err => {
      console.log(err);
    })
  }

  const getYourAllocation = (contract) => {
    web3Utils.getInfoAllocations(contract).then(data => {
      setLayoutAllocation(data.layout)
      setAllocationInfo(data.infoAllocation)
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <>
      {selectedProject ?
        <div className="pp-detail-page">
          <div className="pp-detail-banner mb-lg-5 mb-3">
            <div className="container">
              <section id="welcome" className="section welcome mb-5">
                <div className="welcome__wrapper justify-content-center justify-content-lg-start">
                  <div className="icon-box position-relative zindex2">
                    <img className="mx-auto img-project-logo" src={selectedProject.logo} alt={selectedProject.name} />
                  </div>
                  <div>
                    <h2 className="welcome__title text-center text-lg-start title mb-0 position-relative zindex2">{selectedProject.name}</h2>
                    <p className="mb-0 text-white text-center text-lg-start position-relative" style={{ fontSize: '20px' }}>{selectedProject.description}</p>
                    <div className="item-social position-relative zindex2 justify-content-center justify-content-lg-start">
                      {
                        selectedProject.pancakeswap &&
                        (<a href={selectedProject.pancakeswap} target="_blank">
                          <img height="20" src="/images/pancake-swap.png" />
                        </a>)
                      }
                      {
                        selectedProject.website &&
                        (<a href={selectedProject.website} target="_blank">
                          <i className="fas fa-globe-americas"></i>
                        </a>)
                      }
                      {
                        selectedProject.twitter &&
                        (<a href={selectedProject.twitter} target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>)
                      }
                      {
                        selectedProject.medium &&
                        (<a href={selectedProject.medium} target="_blank">
                          <i className="fab fa-medium-m"></i>
                        </a>)
                      }
                      {
                        selectedProject.telegram &&
                        (<a href={selectedProject.telegram} target="_blank">
                          <i className="fab fa-telegram-plane"></i>
                        </a>)
                      }
                    </div>
                  </div>
                </div>
              </section>
              <div className="intro__section position-relative" style={{ maxWidth: '100%' }}>
                <div className="bordered">
                  <div className="bordered__inner bordered--flex staking-card staking-card-wave">
                    <div className="bordered__block position-relative">
                      <div className="items-morex">
                        {(selectedProject.state === "O" || selectedProject.state === "F") &&
                          <>
                            <span className="pp-status-open text-success"><i className="mdi mdi-circle text-success"></i> {'Open'}</span>
                          </>
                        }
                        {selectedProject.state === "C" &&
                          <>
                            <span className="pp-status-closed"><i className="mdi mdi-circle"></i> {'Closed'}</span>
                          </>
                        }
                        {
                          selectedProject.state === "P" &&
                          <>
                            {
                              selectedProject.openTime !== undefined ?
                                <span className="pp-status-opening text-warning" ><i className="mdi mdi-circle  text-warning"></i> {'Opens in'} <b id={`idOpenTime-${selectedProject["contract"]}`}>0d 0h 0m 0s</b></span>
                                : <span className="pp-status-opening text-warning"><i className="mdi mdi-circle  text-warning"></i>{'TBA'}</span>
                            }
                          </>
                        }
                      </div>
                      {selectedProject.state !== "P" && <div className="mb-4">
                        {account &&
                          <div className="pp-card-info mt-2">
                            <div className="pp-card-col w-100">
                              Your balance<br />
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <strong className="text-white detail-value" id="idBusdBalance">{helpers.formatNumberDownRound(tokenBalance, 4)} {selectedProject.symbol}</strong>
                              </div>
                              {selectedProject.symbol !== "BNB" &&
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                  <strong id="idKcsBalance" className="text-white detail-value">{helpers.formatNumberDownRound(ethBalance, 4)} BNB</strong>
                                </div>
                              }
                              <div className="pp-card-col mb-3 mb-md-0">
                                Your approved amount:<br />
                                <b className="text-white detail-value">{helpers.formatNumberDownRound(allocationNumber, 4)} {selectedProject.symbol}</b>
                              </div>

                            </div>
                            <div className="pp-card-col w-100">
                              {tier && <div>
                                Your tier<br />
                                <b className="text-white detail-value">{tier}</b>
                              </div>}
                            </div>
                          </div>
                        }
                      </div>
                      }

                      {(selectedProject.state === "P" && account) && <div className="mb-4">
                        <div className="card card-img-holder bg-gradient-danger mx-auto mx-lg-0">
                          <div className="card-body py-3 px-3 px-md-4">
                            {account &&
                              <div className="pp-card-info mt-2">
                                <div className="pp-card-col">
                                  Your balance<br />
                                  {
                                    selectedProject.openTime !== undefined ?
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="mb-0"><strong id="idBusdBalance">
                                          {helpers.formatNumberDownRound(tokenBalance, 4) || 0}&nbsp;
                                          {selectedProject.symbol}
                                        </strong></h4>
                                        {
                                          selectedProject.state !== "P" &&
                                          <h6 id="idBusdConvert">1 {selectedProject.symbol} = {selectedProject.rate} {selectedProject.projectTokenSymbol}</h6>
                                        }
                                      </div>
                                      : <div></div>
                                  }

                                  {selectedProject.symbol !== "BNB" &&
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h4 className="mb-0"><strong id="idethBalance">{helpers.formatNumberDownRound(ethBalance, 4) || 0} BNB</strong></h4>
                                    </div>
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      }

                      <div>
                        {
                          !account ?
                            <button type="button" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"} className="btn btn-nft">
                              <span>Connect Wallet</span>
                            </button>
                            :
                            <>
                              {selectedProject.state !== "P" &&
                                <div className="d-flex flex-wrap" style={{ gap: '20px' }}>
                                  <button type="button" disabled={!enableApprove} data-bs-toggle="modal" data-bs-target="#approveModal" className="btn btn-nft" style={{ minWidth: '180px' }}>
                                    <span>Approve</span>
                                  </button>
                                  <button type="button" disabled={!enableJoinProject || allocationNumber == 0} data-bs-toggle="modal" data-bs-target="#buyModal" className="btn btn-nft" style={{ minWidth: '180px' }}>
                                    <span>Join Pool</span>
                                  </button>
                                </div>
                              }
                            </>

                        }
                      </div>
                    </div>
                    <div className="bordered__block position-relative pb-5" style={{ flex: 1 }}>

                      {selectedProject.state !== "P" &&
                        <div>
                          {/* If not connect show open time  else show round time.(if state = 4 ==> show closes)*/}
                          {
                            (selectedProject.state == "O") &&
                            <div className="pp-card-info mt-2">
                              {account ?
                                roundState !== 4 ?
                                  <div className="pp-card-col w-100">
                                    {
                                      textRoundState && <div>
                                        <span id="idTextRoundState"> {textRoundState}</span>
                                        <br />
                                      </div>
                                    }
                                    <b id={`idRoundTime-${selectedProject["contract"]}`} className="detail-value"> 0d 0h 0m 0s</b>
                                  </div> :
                                  <div>ROUND CLOSED</div>
                                :
                                <div className="pp-card-col w-100">First Come First Serve <u>opens</u> in:<br />
                                  <b id={`idFcfsOpenTime-${selectedProject["contract"]}`} className="detail-value"> 0d 0h 0m 0s</b>
                                </div>
                              }
                            </div>
                          }

                          {
                            selectedProject.state == "F" &&
                            <div className="pp-card-info mt-2">
                              {account ?
                                roundState !== 4 ?
                                  <div className="pp-card-col w-100">
                                    <span id="idTextRoundState"> {textRoundState}</span>
                                    <br />
                                    <b id={`idRoundTime-${selectedProject["contract"]}`} className="detail-value"> 0d 0h 0m 0s</b>
                                  </div> :
                                  <div>ROUND CLOSED</div>
                                :
                                <div className="pp-card-col w-100">Closing in:<br />
                                  <b id={`idTimeClose-${selectedProject["contract"]}`} className="detail-value"> 0d 0h 0m 0s</b>
                                </div>
                              }
                            </div>
                          }

                          {
                            selectedProject.state == "C" &&
                            <div className="pp-card-info mt-2 text-white">
                              <div>CLOSED</div>
                            </div>
                          }

                          {
                            account &&
                            <div>
                              <hr className="mb-2 mt-2" />
                              <div className="pp-card-info">
                                <div className="d-flex justify-content-between w-100">
                                  <div className="pp-card-col w-100">
                                    Swapped<br />
                                    <b id="idUseParticipation" className="detail-value">{helpers.formatNumberDownRound(userParticipation, 4)} {selectedProject.symbol}</b>
                                    <div>
                                      <b className="detail-value"> {`${helpers.formatNumberDownRound(userParticipation * selectedProject.rate, 4)} ${selectedProject.projectTokenSymbol}`}</b>
                                    </div>
                                  </div>
                                  <div className="pp-card-col w-100">
                                    Remaining Allocation<br />
                                    <b id="idBusdMaxBuy" className="detail-value">{helpers.formatNumberDownRound(remainingAllocation, 4)} {selectedProject.symbol}</b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                          <hr className="mb-2 mt-2" />
                          <div className="pp-card-info">
                            <div className="d-flex justify-content-between w-100">
                              {/* Progress bar */}
                              {selectedProject.state !== "O" ?
                                <div className="pp-card-col w-100">
                                  Swap progress<br />
                                  <div className="pp-card-progress">
                                    <div className="pp-card-progress-percent" style={{ width: `${(selectedProject.totalFundParticipated / selectedProject.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                                    <div className="pp-card-progress-label">
                                      <span><b>{((selectedProject.totalFundParticipated / selectedProject.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                      <span><b>{selectedProject.totalFundParticipated.toFixed(4)}/{selectedProject.maxTotalParticipationAllocated} {selectedProject.symbol}</b></span>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="pp-card-col w-100 pp-card-progress-wrap light-progress disabled">
                                  <div className="pp-card-progress text-center">
                                    <div className="pp-card-progress-percent pp-card-progress-percent-card"></div>
                                    <div className="pp-card-progress-label">
                                      <span className="participants-center" >Allocation round</span>
                                      <span className="participants-center" style={{ top: "8px" }}>{selectedProject.totalCountUserParticipated} Participants</span>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pp-detail-content">
            <div className="container">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className="py-3">
                    <div className="row gx-xl-5">
                      <div className="col-lg-6">
                        <div className="unlock__bordered bordered mx-auto" style={{ maxWidth: '100%' }}>
                          <div className="bordered__inner bordered--flex staking-card">
                            <div className="bordered__block p-4 pb-3 p-2-mb" style={{ maxWidth: '100%', border: 0 }}>
                              <div className="block-title">
                                <h4>Pool information</h4>
                              </div>
                              <div className="table-responsive">
                                <table className="table mb-0 pp-table-info text-white">
                                  <tbody>
                                    <tr>
                                      <td>Opens</td>
                                      {
                                        selectedProject.openTime === undefined ?
                                          <td className="text-end">
                                            <h5 className="mb-0">TBA</h5>
                                          </td>
                                          : <td className="text-end">
                                            <h5 className="mb-0">{moment(selectedProject.openTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                                          </td>
                                      }
                                    </tr>
                                    <tr>
                                      <td>FCFS Opens</td>
                                      {
                                        selectedProject.fcfsOpenTime === undefined ?
                                          <td className="text-end">
                                            <h5 className="mb-0">TBA</h5>
                                          </td>
                                          : <td className="text-end">
                                            <h5 className="mb-0">{moment(selectedProject.fcfsOpenTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                                          </td>
                                      }
                                    </tr>
                                    <tr>
                                      <td>Closes</td>
                                      {
                                        selectedProject.closeTime === undefined ?
                                          <td className="text-end">
                                            <h5 className="mb-0">TBA</h5>
                                          </td>
                                          : <td className="text-end">
                                            <h5 className="mb-0">{moment(selectedProject.closeTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                                          </td>
                                      }
                                    </tr>
                                    <tr>
                                      <td>Swap Rate</td>
                                      {
                                        selectedProject.openTime !== undefined ?
                                          <td className="text-end">
                                            <h5 className="mb-0" id="idBusdConvert">
                                              <span className="text-nowrap">1 {selectedProject.symbol}</span>
                                              <span className="text-nowrap"> = {selectedProject['rate'].toFixed(4)} {selectedProject.projectTokenSymbol}</span>
                                            </h5>
                                          </td>
                                          : <td className="text-end">
                                            <h5 className="mb-0" id="idBusdConvert">TBA</h5>
                                          </td>
                                      }
                                    </tr>

                                    <tr>
                                      <td>Cap</td>
                                      {
                                        selectedProject.openTime !== undefined ?
                                          <td className="text-end">
                                            <h5 className="mb-0">{selectedProject.maxTotalParticipationAllocated} {selectedProject.symbol}</h5>
                                          </td>
                                          : <td className="text-end">
                                            <h5 className="mb-0">TBA</h5>
                                          </td>
                                      }
                                    </tr>
                                    <tr>
                                      <td>Total Users Participated</td>
                                      <td className="text-end">
                                        <h5 className="mb-0">{selectedProject.totalCountUserParticipated || 0}</h5>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Total Funds Swapped</td>
                                      {
                                        selectedProject.openTime !== undefined ?
                                          <td className="text-end">
                                            <h5 className="mb-0 text-nowrap">{selectedProject.totalFundParticipated.toFixed(4) || 0} {selectedProject.symbol || ""}</h5>
                                          </td>
                                          : <td className="text-end">
                                            <h5 className="mb-0">0</h5>
                                          </td>
                                      }

                                    </tr>
                                    <tr>
                                      <td>Access Type</td>
                                      <td className="text-end">
                                        <h5 className="mb-0">{selectedProject.isPrivate ? "Private" : "Public"}</h5>
                                      </td>
                                    </tr>
                                    <tr><td className="pb-0" colSpan={2}><br /></td></tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="unlock__bordered bordered mx-auto" style={{ maxWidth: '100%' }}>
                          <div className="bordered__inner bordered--flex staking-card">
                            <div className="bordered__block p-4 pb-3 p-2-mb" style={{ maxWidth: '100%', border: 0 }}>
                              <div className="block-title">
                                <h4>Token information</h4>
                              </div>
                              <div className="table-responsive">
                                <table className="table mb-0 pp-table-info text-white">
                                  <tbody>
                                    <tr>
                                      <td>Name</td>
                                      <td className="text-end">
                                        <h5 className="mb-0">{selectedProject.name}</h5>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Token Symbol</td>
                                      <td className="text-end">
                                        <h5 className="mb-0">{selectedProject.projectTokenSymbol}</h5>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <ScheduleTabComponent roundInfo={roundInfo} />
                      </div>
                    </div>
                  </div>
                </div>


                {yourAllocationVisible &&
                  <YourAllocationComponent
                    allocationInfo={allocationInfo}
                    tokenSymbol={selectedProject.projectTokenSymbol}
                    decimals={projectState.decimals}
                    contractAddress={contractAddress}
                    tokenAddress={projectState.projectTokenContract}
                    handleBuyClick={() => setCountBuy(countBuy + 1)}
                    claim={selectedProject.claim}
                    layout={layoutAllocation}
                  />
                }

              </div>
            </div>

            <Container className="mt-5">
              <Footer />
            </Container>
          </div>
        </div>
        :
        <div className="pp-detail-page" style={{ backgroundImage: 'none' }}>
          <div className="pp-detail-banner">
            <div className="container text-white py-5 text-center" style={{ fontSize: '20px' }}>
              Loading...
            </div>
          </div>

          <Container className="mt-5">
            <Footer />
          </Container>
        </div>
      }

      <InvestmentModal walletInfo={walletInfo}
        allocationNumber={allocationNumber}
        remainingAllocation={remainingAllocation} handleBuyClick={() => setCountBuy(countBuy + 1)}
        countClick={countBuy}
        tokenBalance={helpers.formatNumberDownRound(tokenBalance, 4)}
        symbol={selectedProject?.symbol}
        handleInputAmount={(n) => setAmountPurchased(n)} />

      <ApproveModal
        remainingAllocation={remainingAllocation}
        walletInfo={walletInfo}
        handleBuyClick={() => setCountBuy(countBuy + 1)}
        handleInputAmount={(n) => setAmountPurchased(n)}
        tokenBalance={helpers.formatNumberDownRound(tokenBalance, 4)} />


    </>
  );
};

export default ProjectDetailPage;
