export const MODE = process.env.REACT_APP_MODE || "TESTNET";

export const BSC_EXPLORER = {
    56: "https://bscscan.com",
    97: "https://testnet.bscscan.com",
};



export const STAKING_CONTRACT_ADDRESS = {
    56: "0x89D92bE368eEFE1fB9d83988d78E5b8EB518C695",
    97: "0xe0b0C16038845BEd3fCf70304D3e167Df81ce225",
    1: "0x173A5c5E0106ca4F49cE91F042e32af97974035B",
};

export const BSC_NFTL_ADDRESS = {
    56: "0x5A726a26eDB0Df8Fd55f03cc30aF8A7cEa81e78D",
    97: "0xe0d24e7695fd9dcc57a097388811fbaeece68ce7",
};

export const POOL_INTERVAL = 15000;



export const BACKEND_URI = "https://app-ido.nftlaunch.network";

// export const BACKEND_URI = 'https://t.nftlaunch.network'

export const NODE_URI = {
    56: [
        "https://bsc-dataseed.binance.org/",
        "https://bsc-dataseed1.defibit.io/",
        "https://bsc-dataseed1.ninicoin.io/",
        "https://bsc-dataseed3.binance.org/",
        "https://bsc-dataseed4.defibit.io/",
        "https://bsc-dataseed3.ninicoin.io/",
        "https://young-necessary-layer.bsc.quiknode.pro/491437596fc64546ca9a2f1e0fe4abb423114e30/"

    ],
    97: ["https://data-seed-prebsc-1-s2.binance.org:8545/"],
    1: [process.env.REACT_APP_NODE_INFURA_URI_1]
};

export const KYC_BACK_END_URI = "https://bscpad.com";

export const chainIdsSupport = [56];

export const defaultChainId = 56
    // process.env.REACT_APP_MODE === "MAINNET" ? 56 : 97;

export const showKYC = 1000.0