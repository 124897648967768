import React, { useCallback, useEffect, useState } from 'react';
import { actAlertMsgWarning } from '../../redux/action';
import { useDispatch } from 'react-redux';
import { isMobile } from 'web3modal';
import { ACTION_CONST, extensionName } from '../constants';
import { isBinanceExtensionAvailable, isMetamaskAvailable, isTrustWalletAvailable } from '../utils/utils';
import WalletExtensionUtils from "../utils/walletExtensionUtils";
import { useActiveWeb3React } from '../../hook';
import useAuth from '../../hook/useAuth';
import Web3Helper from '../utils/walletExtensionUtils';
import { connectorLocalStorageKey } from '../../components/literals';
import { connectors } from '../../constants';

const ConnectWalletModal = (props) => {
    // const wallet = useWallet();
    const dispatch = useDispatch();

    const { login } = useAuth();
    const { account, library, error, chainId } = useActiveWeb3React()

    const handleConnectClick = useCallback((walletConfig) => {
        try {
            login(walletConfig.connectorId);
            window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
        } catch (error) {
            console.log(error);
        }
    }, [])

    useEffect(() => {

        if (error)
            return;

        if (account && library && library.provider) {
            const web3 = new Web3Helper(library.provider, account, chainId)

            dispatch({
                type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
                data: web3
            })
            return;
        }

    }, [library, account, error])
        // onDi

    //show hide button
    // const [hasMetamask, setHasMetamask] = useState(false);
    // const [hasTrustWallet, setHasTrustWallet] = useState(false);
    // const [hasBinanceWallet, setHasBinanceWallet] = useState(false);

    const [modalConnectBSCTitle, setModalBSCConnectTitle] = useState("")

    // useEffect(() => {
    //     setTimeout(() => {
    //         addWallet();
    //     }, 3000);
    // }, []);
    // const addWallet = () => {
    //     setHasMetamask(isMetamaskAvailable());
    //     setHasTrustWallet(isTrustWalletAvailable());
    //     setHasBinanceWallet(isBinanceExtensionAvailable())
    //     if (isMobile() && !isTrustWalletAvailable() && !isMetamaskAvailable()) {
    //         dispatch({ type: ACTION_CONST.SET_SHOW_MODAL_HELP })
    //     }
    // };

    // const connectWithExtension = async (extensionName) => {
    //     const temp = new WalletExtensionUtils(extensionName);
    //     await temp.connect();
    //     if (temp.checkWrongNetwork()) {
    //         dispatch(
    //             actAlertMsgWarning(
    //                 "Wrong network! You need connect to Binance smart chain network!"
    //             )
    //         );
    //         return;
    //     }

    //     //Show Block UI
    //     dispatch({
    //         type: ACTION_CONST.REQUEST_SUBMIT
    //     })

    //     //Disable Block UI
    //     dispatch({
    //         type: ACTION_CONST.REQUEST_DONE
    //     })


    //     dispatch({
    //         type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
    //         data: temp
    //     })


    //     // setIsSigning(extensionName);
    //     await getBalanceAndAddress(temp);

    //     // iff account exchange
    //     try {
    //         temp.accountsChanged(async (res) => {
    //             if (res !== undefined) {
    //                 // console.log('account changed')
    //                 dispatch({
    //                     type: ACTION_CONST.CLEAR_KYC_STATE
    //                 })
    //                 await getBalanceAndAddress(temp);

    //             }
    //         });

    //         return true;
    //     } catch (e) {
    //         dispatch({
    //             type: ACTION_CONST.REQUEST_DONE
    //         })
    //         console.log("error: ", e);
    //     }

    //     //if chain ID
    //     try {
    //         temp.chainChanged(async (chanId) => {
    //             await temp.connect();
    //             if (temp.checkWrongNetwork()) {

    //                 dispatch(
    //                     actAlertMsgWarning(
    //                         "Wrong network! You need connect to Binance smart chain network!"
    //                     )
    //                 );
    //                 return;
    //             }
    //             await getBalanceAndAddress(temp);
    //         })
    //     } catch (error) {

    //     }

    // };

    // const getExtension = () => {
    //     return extensionName;
    // };

    const getBalanceAndAddress = async (extension) => {
        const walletAddress = await extension.getCurrentAddress();
        dispatch({
            type: ACTION_CONST.CONNECT_WALLET_SUCCESS,
            data: walletAddress
        })
    };





    return (
        <>
            <div className="modal fade" id="connectWalletModal" tabIndex="-1" aria-labelledby="connectWalletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connectWalletModalLabel">Connect to wallet</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">

                        {connectors.map((entry, key) => {
                                if (entry.enable === true) {
                                    return (

                                        <button type="button" className="btn-select-network" key ={key}data-bs-dismiss="modal" onClick={() => 
                                            handleConnectClick(entry)
                                        }>
                                            <img className="btn__icon" src={entry.icon} height="26" className="me-2" alt={entry.connectorId}  />
                                            <span>{entry.title}</span>
                                        </button>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConnectWalletModal;


