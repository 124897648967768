import React, { useState, } from "react";
import { ACTION_CONST } from "../../../constants";
import { useDispatch } from "react-redux";
import { helpers } from "../../../shared/utils/helpers";
import { useProjectName, useProjectSymbol, useSelectedProject, useWeb3Utils } from "../../../hook/useState";

const ApproveModal = (props) => {
  const dispatch = useDispatch();
  const selectedProject = useSelectedProject();
  const web3Utils = useWeb3Utils();
  const [amount, setAmount] = useState(0);
  const projectName = useProjectName();
  const symbol = useProjectSymbol();
  const [enableAprBtn, setEnableAprBtn] = useState(false);

  const handleOnchangeAmount = (e) => {
    const amountInputValue = Number(e.target.value);
    if (0 < amountInputValue
      && amountInputValue <= props.walletInfo.remainingAllocation
      && amountInputValue <= props.walletInfo.tokenBalance) {
      setEnableAprBtn(true);
    } else {
      setEnableAprBtn(false)
    }

    setAmount(amountInputValue)
    props.handleInputAmount(amountInputValue)
    return;
  }

  const handleClickMax = () => {
    if (props.walletInfo.tokenBalance > 0 && props.walletInfo.remainingAllocation <= props.walletInfo.tokenBalance) {
      setAmount(helpers.formatNumberDownRound(props.walletInfo.remainingAllocation, 6))
      document.getElementById('inputAmountApprove').value = helpers.formatNumberDownRound(props.walletInfo.remainingAllocation, 6);
      setEnableAprBtn(true);
      return;
    }
    if (props.walletInfo.remainingAllocation > 0 && props.walletInfo.remainingAllocation > props.walletInfo.tokenBalance) {
      setAmount(helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 6))
      document.getElementById('inputAmountApprove').value = helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 6)
      setEnableAprBtn(true);
      return;
    }
    setEnableAprBtn(false);
  }

  const handleApprove = () => {
    if (web3Utils && selectedProject) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      web3Utils.approve({
        contractAddress: selectedProject.contract,
        tokenContractAddress: selectedProject.tokenAddress,
        amount: Number(amount)
      }, (data) => {

        if (data.status == "APPROVED") {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          props.handleBuyClick()
          document.getElementById("inputAmountApprove").value = 0;
          setEnableAprBtn(false);
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: "Approve Success"
          })
        }
        if (data.status == "APPROVE_FAILS") {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: "Failed to Approve Tokens"
          })
        }
      }).catch(err => {
        dispatch({
          type: ACTION_CONST.ALERT_SUCCESS,
          message: "Token Approve Fails! Please try again!"
        })
        dispatch({ type: ACTION_CONST.REQUEST_DONE })
      })
    }
  }

  return (
    <div
      className="modal fade"
      id="approveModal"
      tabIndex="-1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="approveModalLabel">Join {projectName} Pool</h5>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn-close me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="modal-body px-0 pb-4">
            <div className="p-form-group">
              <label className="form-label p-main-text mb-2">{symbol} Amount</label>
              <div className="d-flex align-items-center justify-content-between px-3 w-100 input-amount">
                <input type="number" className="form-control px-0" placeholder={0.0} defaultValue={0} autoFocus onChange={(e) => handleOnchangeAmount(e)} id="inputAmountApprove" />
                <span style={{ cursor: 'pointer' }} onClick={handleClickMax}>MAX</span>
              </div>
            </div>
            <div className="d-flex align-items-start justify-content-between">
              <div>Your balance: <b className="text-warning">{props?.tokenBalance} {props?.symbol}</b></div>
              <div />
            </div>

            <div className="get-start mt-4 text-center" style={{ columnGap: '10px' }}  >
              <button type="button" disabled={!enableAprBtn} onClick={() => handleApprove()} data-bs-dismiss="modal" className="btn btn-nft" style={{ minWidth: '180px' }}>
                <span>Approve</span>
              </button>
            </div>
            {/* </BlockUi> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveModal;




