export const ROUTES = {
    PROJECTS: '/projects',
    PROJECT_DETAIL: "/projects/:contract",
    STACKING: '/staking',
    UNSTACKING: '/unstaking',
    WITHDRAW: '/withdraw',
    PRIVACY_POLICY: '/privacy-policy',
    COOKIES_POLICY: '/cookies-policy',
    TERM_OF_USES: '/terms-conditions'
};

export const defaultAvatar = "./images/user-thumb-lg.png";