import Web3 from "web3";
import stakingABIContract from "../constants/abi/staking.json";
import { BigNumber } from "bignumber.js";
import _ from "lodash";
import {
  STAKING_CONTRACT_ADDRESS,
  NODE_URI,
  defaultChainId,
} from "../../_configs";

import erc20Abi from "../constants/abi/erc20.abi.json";
let bscURI = NODE_URI[defaultChainId][_.random(0, NODE_URI[defaultChainId].length - 1)];
let web3;

//get info from staking contract

export async function getStakingContractInfo() {
  // console.log("NODE_URI===>", NODE_URI)
  // const bscURI = NODE_URI[defaultChainId][_.random(0, NODE_URI[defaultChainId].length - 1)];
  // console.log("provider URI==>", bscURI);
  web3 = new Web3(bscURI);
  const stakingContract = new web3.eth.Contract(
    stakingABIContract,
    STAKING_CONTRACT_ADDRESS[defaultChainId]
  );

  try {
    const info = await stakingContract.methods.info().call();
    return {
      tokenAddr: info[0],
      tokenSymbol: info[1],
      tokenDecimals: Number(info[2]),
      stakerCount: Number(info[3]),
      totalStakedAmount: BigNumber(info[4])
        .dividedBy(10 ** 18)
        .toString(),
      apyPercentage: Number(info[5]),
      unstakePeriod: Number(info[6]),
      isStakingPaused: info[7],
      totalRewardsDistributed: BigNumber(info[8])
        .dividedBy(10 ** 18)
        .toString(),
    };
  } catch (error) {
    console.log(error);
    return {
      tokenAddr: 0,
      tokenSymbol: 0,
      tokenDecimals: 0,
      stakerCount: 0,
      totalStakedAmount: 0,
      apyPercentage: 0,
      unstakePeriod: 0,
      isStakingPaused: 0,
      totalRewardsDistributed: 0,
    };
  }
}

export const getStakeAmountWallet = async (chainId, account) => {
  let uri = NODE_URI[chainId][_.random(0, NODE_URI[chainId].length - 1)];
  const web3 = new Web3(uri);
  const stakingContract = new web3.eth.Contract(
    stakingABIContract,
    STAKING_CONTRACT_ADDRESS[chainId]
  );

  try {
    const infoWallet = await stakingContract.methods.infoWallet(account).call();

    return new BigNumber(infoWallet[0].toString())
      .dividedBy(10 ** 18)
      .toFixed(18)
      .replace(/\.?0+$/, "")
      .toString();
  } catch (error) {
    return 0;
  }
};

export const getAmountPadToken = async (chainId, account) => {
  let uri = NODE_URI[chainId][_.random(0, NODE_URI[chainId].length - 1)];
  const web3 = new Web3(uri);
  const stakingContract = new web3.eth.Contract(
    stakingABIContract,
    STAKING_CONTRACT_ADDRESS[chainId]
  );

  const tokenAddress = await stakingContract.methods.token().call();

  const tokenContract = new web3.eth.Contract(erc20Abi, tokenAddress);
  const tokenBalance = await tokenContract.methods.balanceOf(account).call();
  return new BigNumber(tokenBalance.toString())
    .dividedBy(10 ** 18)
    .toFixed(18)
    .replace(/\.?0+$/, "")
    .toString();
};
