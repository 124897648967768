import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { actGetListProjects } from "../../redux/action/user";
import ClosedProjectsComponent from "./components/ClosedProjectsComponent";
import OpeningProjectsComponent from "./components/OpeningProjectsComponent";
import WaitingProjectsComponent from "./components/WaitingProjectsComponent";
import Footer from "../../shared/components/Footer";
import './index.css';
import { useLatestBlockNumber } from "../../hook/useState";

const ProjectsPage = () => {
    const dispatch = useDispatch();
    const latestBlock = useLatestBlockNumber();

    useEffect(() => {
        dispatch(actGetListProjects())
    }, [latestBlock])

    useEffect(() => {
        // Init
        var container = document.getElementById("BannerContainer"),
          inner = document.getElementById("BannerImage");
    
        // Mouse
        var mouse = {
          _x: 0,
          _y: 0,
          x: 0,
          y: 0,
          updatePosition: function (event) {
            var e = event || window.event;
            this.x = e.clientX - this._x;
            this.y = (e.clientY - this._y) * -1;
          },
          setOrigin: function (e) {
            this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
            this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
          },
          show: function () {
            return "(" + this.x + ", " + this.y + ")";
          }
        };
    
        // Track the mouse position relative to the center of the container.
        mouse.setOrigin(container);
    
        //-----------------------------------------
    
        var counter = 0;
        var updateRate = 10;
        var isTimeToUpdate = function () {
          return counter++ % updateRate === 0;
        };
    
        //-----------------------------------------
    
        var onMouseEnterHandler = function (event) {
          update(event);
        };
    
        var onMouseLeaveHandler = function () {
          inner.style = "";
        };
    
        var onMouseMoveHandler = function (event) {
          if (isTimeToUpdate()) {
            update(event);
          }
        };
    
        //-----------------------------------------
    
        var update = function (event) {
          mouse.updatePosition(event);
          updateTransformStyle(
            (mouse.y / inner.offsetHeight / 2).toFixed(2),
            (mouse.x / inner.offsetWidth / 2).toFixed(2)
          );
        };
    
        var updateTransformStyle = function (x, y) {
          var style = "rotateX(" + (x * 20) + "deg) rotateY(" + (y * 20) + "deg)";
          var style = "translate(" + (x * 20) + "px, " + (y * 20) + "px)";
          inner.style.transform = style;
          inner.style.webkitTransform = style;
          inner.style.mozTransform = style;
          inner.style.msTransform = style;
          inner.style.oTransform = style;
        };
    
        //-----------------------------------------
    
        container.onmouseenter = onMouseEnterHandler;
        container.onmouseleave = onMouseLeaveHandler;
        container.onmousemove = onMouseMoveHandler;
    
      }, [])

    return <div className="projects-page">
        <div className="p-banner-projects py-3">
            <div className="container" id="BannerContainer">
                <div className="row align-items-center">
                    <div className="col-lg-6 mt-5 mt-lg-0 text-center text-lg-start">
                        <img src="/images/t1.svg" id="BannerImage" />
                        <div>
                            <img src="/images/t2.svg" style={{ maxWidth: '100%' }} />
                        </div>
                        <p className="mt-5 text-white-50" style={{ fontSize: '20px', lineHeight: 1.5 }}>The FIRST deflationary NFT Launchpad that offers no gas wars, fair distribution and FREE NFT airdrops of new and upcoming NFT projects.</p>
                        <div className="d-flex justify-content-center justify-content-lg-start flex-wrap mt-5" style={{ gap: '20px', gridGap: '20px' }}>
                            <a className="btn btn-nft" target="_blank" href="https://nftlaunch.network/"><span>Homepage</span></a>
                            <a className="btn btn-nft" target="_blank" href="https://staking.nftlaunch.network/"><span>Staking</span></a>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <img className="d-none d-lg-block" src="/images/123.png" style={{ maxWidth: '100%' }} />
                    </div>
                </div>
            </div>
        </div>
        <div className="pp-projects-page mt-5 mb-5 pt-3">
            <Container>
                <OpeningProjectsComponent />
                <WaitingProjectsComponent />
                <ClosedProjectsComponent />
            </Container>
            <Container className="mt-5">
                <Footer />
            </Container>
        </div>
    </div>
}
export default ProjectsPage;