import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { isMobile } from "web3modal";
import exactMath from 'exact-math';
import { ACTION_CONST, ROUTES } from "../constants";
import { helpers } from "../../shared/utils";
import $ from 'jquery';
import { useIsConnectWallet, usePadTokenBalance, useWeb3Utils } from "./hook/useSate";
import { useKycStatus, useLatestBlockNumber, useStakeAmount } from "../../hook/useState";
import { useActiveWeb3React } from "../../hook";
import { getKYC } from "../../redux/services/kyc.api";
import { showKYC } from "../../_configs";
import { getAmountPadToken, getStakeAmountWallet } from "../utils/bscHelpers";

const Header = () => {
  const dispatch = useDispatch();
  const { account, library, chainId } = useActiveWeb3React()
  const walletUtils = useWeb3Utils()
  const nftlBalance = usePadTokenBalance();


  const latestBlock = useLatestBlockNumber()
  const kycStatus = useKycStatus();

  const stakeAmount = useStakeAmount();
  const [enableKYC, setEnableKYC] = useState(false);

  const [bscStaked, setBSCStaked] = useState(0);
  const [bscPadBalance, setBSCPadBalance] = useState(0);
  const [ethStaked, setETHStaked] = useState(0);
  const [ethPadBalance, setETHPadBalance] = useState(0);
  //set balance
  useEffect(() => {

    if (walletUtils && account) {

      //get bnb balance
      walletUtils.web3.eth.getBalance(account).then(balance => {

        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })

      walletUtils.getTokenPadBalance().then(data => {

        dispatch({
          type: ACTION_CONST.GET_NFTL_WALLET_BALANCE,
          data: data
        })
      })

      //get staking wallet Info
      walletUtils.getStakingInfoWallet().then(data => {
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });
    }

  }, [latestBlock, walletUtils, account]);


  //get info account
  useEffect(() => {
    if (account) {
      getStakeAmountWallet(56, account).then(result => {
        // console.log("staked bsc==>", result);
        setBSCStaked(result)
      })
      getStakeAmountWallet(1, account).then(result => {
        // console.log("staked eth==>", result);
        setETHStaked(result);
      })

      getAmountPadToken(56, account).then(balance => {
        // console.log("balance bsc==>", balance);
        setBSCPadBalance(balance)
      })
      getAmountPadToken(1, account).then(balance => {
        // console.log("balance eth==>", balance);
        setETHPadBalance(balance)
      })
    }



  }, [account, latestBlock])

  //check show hide KYC status
  useEffect(() => {
    if (!account) {
      return;
    }
    if ((Number(bscStaked) + Number(bscPadBalance) + Number(ethStaked) + Number(ethPadBalance)) >= showKYC) {

      setEnableKYC(true)

      getKYCAddress(account)

    } else {
      setEnableKYC(false)
    }

  }, [chainId, account, stakeAmount, latestBlock, nftlBalance])


  //get kyc
  const getKYCAddress = (address) => {
    getKYC(address, 'state').then(response => {
      address = address.toLowerCase()
      if (response) {
        const state = response.state;
        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }

    }).catch(err => {
      console.log(err);
    })
  }

  const handleOnclickKyc = () => {
    getKYC(account, 'url').then(data => {
      if (data) {
        const url = data.url
        window.open(url, "_blank")
      }

    }).catch(err => {
      console.log(err);
    })
  }

  const handleTabChange = () => {
    $('.navbar-toggler').trigger('click');
  }

  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-lg navbar-dark">
        <div className="container-fluid">
          <a className="navbar-brand d-flex align-items-center" href="https://nftlaunch.network/">
            <img src="/images/logo_w.png" height="41" alt="nftlaunch" className="me-2" />
          </a>
          {isMobile() &&
            <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
              <div className="dropdown d-block d-md-none d-lg-none d-xl-none">

              </div>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          }

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-3 mt-3 mt-lg-0 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href='https://staking.nftlaunch.network/staking' target="_blank">Stake</a>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link active" to={ROUTES.PROJECTS} onClick={handleTabChange}>Projects</NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" href='https://app.nftlaunch.network' target="_blank">NFTs</a>
              </li>
            </ul>
            <ul className={account ? 'navbar-nav ms-auto mb-2 mb-md-0 connected' : 'navbar-nav ms-auto mb-2 mb-md-0'}>
              {
                !account ?
                  <li className="nav-item me-2">
                    <div className="d-flex align-items-center justify-content-end">
                      <a href="#" data-bs-toggle="modal" data-bs-target={"#connectWalletModal"} className="btn btn-nft">
                        <span>Connect Wallet</span>
                      </a>
                    </div>
                  </li>
                  :
                  <>
                    <li className="nav-item me-2">
                      <a href="#" data-bs-toggle="modal" data-bs-target={"#walletModal"} className="btn btn-nft">
                        <span><b>{helpers.formatTransactionHash(account, 4, 4)}</b> - {helpers.formatNumberDownRoundWithExtractMax(nftlBalance, 4)} $NFTL</span>
                      </a>
                    </li>
                    {
                      enableKYC &&
                      <>
                        {
                          kycStatus === 'START' &&
                          <li className="nav-item">
                            <button className="btn btn-warning btn-kyc btn-kyc-start ms-2" onClick={() => handleOnclickKyc()}>
                              <i className="mdi mdi-pencil-plus me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                              <span >KYC</span>
                            </button>
                          </li>
                        }
                        {
                          kycStatus === 'PENDING' &&
                          <li className="nav-item">
                            <button className="btn btn-warning btn-kyc btn-kyc-pending ms-2" onClick={() => handleOnclickKyc()}>
                              <i className="mdi mdi-exclamation-thick me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                              <span>KYC</span>
                            </button>
                          </li>
                        }
                        {
                          kycStatus === 'APPROVED' &&
                          <li className="nav-item">
                            <button className="btn btn-success btn-kyc btn-kyc-success ms-2">
                              <i className="mdi mdi-check-bold me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                              <span>KYC</span>
                            </button>
                          </li>
                        }
                        {
                          kycStatus === 'ERROR' &&
                          <li className="nav-item">
                            <button className="btn btn-danger btn-kyc btn-kyc-failed ms-2" onClick={() => handleOnclickKyc()}>
                              <i className="mdi mdi-alert-octagon-outline me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                              <span>KYC</span>
                            </button>
                          </li>
                        }
                      </>
                    }

                  </>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
