import React from "react";
import { Link } from "react-router-dom";
import { getCountDown, remainTime } from "../../../shared/utils/helper";
import { useComingSoonProjects } from "../../../hook/useState";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const WaitingProjectsComponent = () => {
  const waitingProjects = useComingSoonProjects()

  return (
    <>
      <section id="welcome" className="section mb-5">
        {/* <div className="wrapper">
          <div className="welcome__wrapper text-center">
            <img src="/images/coming.png" style={{ maxHeight: '130px', maxWidth: '100%' }} />
          </div>
        </div> */}
        <h2 className="section-title text-center">Projects Coming Soon</h2>
      </section>
      <div className="pp-investment pt-0" style={{ backgroundImage: "none" }}>
        {/* <img src="/images/rocket.png" width="350" className="position-absolute d-none d-xl-block" style={{ right: '-100px', top: '-50px' }} /> */}
        <div className="text-center">
          <div className="investment-section-items">
            <div className="row gx-md-5 justify-content-xl-center">
              {waitingProjects.length > 0 ?
                waitingProjects.map((item, key) => {
                  getCountDown(`idOpenTime-${item["contract"]}-${item["openTime"]}`, item.openTime * 1000, function start(job) {
                  }, function end(job) {
                  })
                  return (
                    <div className="col-xl-4 col-lg-6 col-md-6 text-center" key={key}>
                      <div className="level-item bordered project-item mb-4 mt-xl-3 staking-card style-3">
                        <div className="bordered__inner bordered--double_angled bordered--bg_top">
                          <div className="level-item__title-wrap">
                            <div className="level-item__title title my-3">
                              {item.state === "P" &&
                                <>
                                  {

                                    (item.contract === 'TBA' || item.openTime === 0 || item.rate == 0) ?
                                      <span className="pp-status-opening">Opens in TBA<b></b></span>
                                      : <span className="pp-status-opening">Opens in <b id={`idOpenTime-${item["contract"]}-${item["openTime"]}`}>{remainTime(item.openTime * 1000)}</b></span>
                                  }
                                </>
                              }
                              {
                                item.contract !== 'TBA' ?
                                  <div><span className="symbol">{item.symbol}</span></div>
                                  : <div></div>
                              }
                            </div>
                          </div>
                          <div className="level__inner pb-0 pt-3">
                            <Link to={(item.contract && item.contract !== 'TBA') ? `projects/${item.contract}` : `#`} className="text-decoration-none w-100 px-4" >
                              <div className="level-item__img mb-4">
                                <img src={item.logo} alt="" />
                                {
                                  item.athMultiplier &&
                                  <div className="pp-top-tag">
                                    <div><small>ATH</small></div>
                                    <div><b>{item.athMultiplier}x</b></div>
                                  </div>
                                }
                              </div>
                              <div className="level-item__digit title">{item.name}</div>
                              <div className="level-item__info pb-4 text-start mt-2">
                                <div className="text-center mb-4 level-item__desc">
                                  {item.description}
                                </div>
                                <ul className="mb-3">
                                  <li>
                                    <span>Swap rate</span><br />
                                    <b className="nowrap text-white">
                                      {!item.contract ? "TBA" :
                                        item.contract !== 'TBA' ?
                                          item.rate !== 0 ?
                                            (`1 ${item.symbol} = ${item["rate"]} ${item["projectTokenSymbol"]}`)
                                            : 'TBA'
                                          : "TBA"}

                                    </b>
                                  </li>
                                  <li>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div>
                                        <span>Cap</span><br />
                                        <b className="nowrap text-white">
                                          {
                                            !item.contract ? "TBA" :
                                              item.contract !== 'TBA' ?
                                                `${item.maxTotalParticipationAllocated || 0} ${item.symbol || ""}`
                                                : `TBA`
                                          }
                                        </b>
                                      </div>
                                      <div className="pe-3">
                                        <span>Access</span><br />
                                        <b className="nowrap text-white">
                                          {item.isPrivate ? "Private" : "Public"}
                                        </b>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    {
                                      <div className={item.state == 'O' ? 'pp-card-progress-wrap disabled' : 'pp-card-progress-wrap'}>
                                        <div className="mb-2 d-flex justify-content-between align-items-center pp-card-progress-top">
                                          <div className="pp-card-col">Progress</div>
                                          {item.contract !== 'TBA' && item.state != 'P' && <div className="pp-card-col text-end">Participants <b className="text-participants font-12">{item['totalCountUserParticipated']}</b></div>}
                                        </div>
                                        <div className='pp-card-progress'>
                                          <div className="pp-card-progress-percent" style={{ width: `${(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                                          <div className="pp-card-progress-label">
                                            <span><b>{((item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                            {item.state == 'O' && <span className="participants-center"><b>{item['totalCountUserParticipated']}</b> Participants</span>}
                                            <span className="text-allocation"><b>{item.totalFundParticipated.toFixed(4)}/{item.maxTotalParticipationAllocated}</b></span>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  </li>
                                </ul>
                              </div>
                            </Link>
                            <div className="item-social">
                              {
                                item.pancakeswap &&
                                <a style={{ backgroundColor: '#47d4dc' }} href={item.pancakeswap} target="_blank"><img height="18" src="/images/pancake-swap.png" /></a>
                              }
                              {
                                item.website &&
                                <a href={item.website} target="_blank"><i className="fas fa-globe"></i></a>
                              }
                              {
                                item.twitter &&
                                <a href={item.twitter} target="_blank"><i className="fab fa-twitter"></i></a>
                              }
                              {
                                item.medium &&
                                <a href={item.medium} target="_blank"><i className="fab fa-medium-m"></i></a>
                              }
                              {
                                item.telegram &&
                                <a href={item.telegram} target="_blank"><i className="fab fa-telegram"></i></a>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="bordered__icon bordered__icon--left">
                          <img src="/images/bordered-lines.svg" alt="" />
                        </div>
                        <div className="bordered__icon bordered__icon--right">
                          <img src="/images/bordered-lines-narrow.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  )
                })
                :
                <span className="text-white fs-mb-16" style={{ fontSize: '24px' }}>
                  No projects currently coming soon
                </span>
              }
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default WaitingProjectsComponent;
