import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Footer from "../../shared/components/Footer";
import * as $ from 'jquery';

const TermOfUsePage = () => {
    useEffect(() => {
        $('body').addClass('no-bg');
        return (() => {
            $('body').removeClass('no-bg');
        })
    }, [])

    return <>
        <Container>
            <div className="w-100" style={{ minHeight: 'calc(100vh - 168px)' }}>
                <div className="py-5">
                    <div>
                        <div className="text-center mb-5">
                            <h1 className="p-tab-title">NFT LAUNCH LIMITED</h1>
                            <h4 className="mb-4">Terms and Conditions of <a href="https://nftlaunch.network/" target="_blank">https://nftlaunch.network</a></h4>
                            <small>Last revised Date: 20<sup>th</sup> May 2022</small>
                        </div>
                    </div>
                    <div>
                        <h3>1. Introduction</h3>
                        <p style={{ lineHeight: '1.6' }}>These Terms and Conditions (herein referred to as “<strong>Terms</strong>”) govern the use and the conditions of NFTLaunch website at https://nftlaunch.network/ (herein referred to as “<strong>Website</strong>”), and the Services provided by NFT Launch Limited a company operating the NFTLaunch Platform and incorporated and registered under the laws of the Republic of Seychelles (herein referred to “<strong>Company</strong>” or “<strong>we</strong>” or “<strong>us</strong>”). These Terms constitute a binding and enforceable legal contract between the Company and its Affiliates and subsidiaries worldwide and you, a user of the Services (herein referred to as “<strong>you</strong>” or<span>&nbsp;</span><strong>“User”)</strong><span>&nbsp;</span>in relation to the Services. You and the Company are referred to separately as “<strong>Party</strong>” and collectively as “<strong>Parties</strong>”.</p>
                        <p style={{ lineHeight: '1.6' }}>By accessing, registering, using, or clicking on the Services, and information made available by the Company via the Website, you hereby accept and agree to all the terms set forth herein.</p>
                        <p style={{ lineHeight: '1.6' }}>You are strongly advised to carefully read these Terms as well as the provisions detailed in our Privacy Policy prior to using the Website and our Services. By using the Website and the Services in any capacity, you agree that: (i) You have read and familiarized yourself with these Terms; (ii) You understand these Terms; and (iii) You agree to be bound by these Terms when using the Website. If You do not agree to these Terms, please do not access or use the Website and the Services.</p>
                        <p style={{ lineHeight: '1.6' }}>We reserve the right to modify or amend these Terms, the Website, or any content on one of the NFTLaunch Platform and the Partners’ platforms from time to time, including for security, legal, or regulatory reasons, as well as to reflect updates or changes to the services or functionality of the Website. You are advised to check these Terms periodically to ensure that you are cognizant of the current versions and comply with them. Users of the Website and the Services are bound by these changes which will take immediate effect after the revised versions of these Terms have been published on the Website or the relevant mobile application. Through your continued use of or interaction with the Website, the Services, tools, and information made available on the NFTLaunch Platform and the Partners’ platforms, you hereby agree to be bound by the provisions highlighted in the subsequent versions.</p>
                        <p style={{ lineHeight: '1.6' }}>We will provide a notification on the Website specifying that changes have been made to these Terms whenever they occur. By accepting the notification, you accept that we have provided you with sufficient notice of any changes. You should seek professional advice regarding any possible legal requirements you must comply with in relation to the use of the Website, the Services or the relevant tools provided by the Company or the Partners.</p>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>2. Definitions</h3>
                        <p style={{ lineHeight: '1.6' }}><strong>"ADAPAD"<span>&nbsp;</span></strong>refers to the platform for Cardano Smart Chain Network, which is a platform for retail investment into tokens offered by Initial DEX Offerings or Initial Decentralized Exchange Offerings. Please find further details at https://adapad.io/.&nbsp;</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Affiliate(s)"<span>&nbsp;</span></strong>refers to in relation in any party, any other company which, directly or indirectly, (i) is controlled by that party, (ii) controls that party, or (iii) is under common control with that party, and in respect of the Company, shall also include any fund, limited partnership or other collective investment vehicle or other person which is managed or advised by the Company’s team.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"AML"<span>&nbsp;</span></strong>refers to anti-money laundering.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Applicable Laws"<span>&nbsp;</span></strong>refers to acts, statutes, regulations, ordinances, treaties, guidelines, and policies issued by governmental organizations or regulatory bodies, including, but not limited to, the governing law stipulated under Laws of the Republic of Seychelles.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Bridge Service"<span>&nbsp;</span></strong>as defined in Clause 6.1.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"CFT"<span>&nbsp;</span></strong>refers to Combating the Financing of Terrorism.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Confidential Information"<span>&nbsp;</span></strong>refers to any non-public, proprietary information or documents of or related to the User or the Company (whether in writing, orally or by any other means) by or on behalf of the User to the Company and which if disclose in tangible or intangible form is marked confidential (including in visual, oral, or electronic form) relating to Us or any other User that was previously a User, which is provided or disclosed through the Company (or to any employees or agents) in connection with the use or participate in the Services.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Content"</strong><span>&nbsp;</span>refers to all content generated by the Company, including logos, identifying marks, images, illustrations, designs, icons, photographs, videos, text, any written or multimedia materials, services, advertisements, software, code, data, files, archives, folders, other elements related to the Company or available downloads on the Website.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Cookie"<span>&nbsp;</span></strong>refers to the small text files that are placed on your computer by the Website that you visit. They are widely used in order to make Websites work, or work more efficiently, as well as to provide information to the owner of the site.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Creator"</strong><span>&nbsp;</span>refers to a creator seeking to list works for sale on the NFTLaunch Platform.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"CrossSwap"</strong><span>&nbsp;</span>refers to the cross-chain swap designed to unify the trading service on one platform. Please find further details at https://crossswap.com/.&nbsp;</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"CrossWallet"</strong><span>&nbsp;</span>refers to the non-custodian wallet service that holds any digital asset you prefer to hold, trade, or send, from tokens to NFTs, which it works seamlessly to move between different blockchain and works on any device, either mobile or web. Please find further details at https://crosswallet.app/.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"ETHPAD Platform"<span>&nbsp;</span></strong>refers to the platform for Ethereum Smart Chain Network, which is a platform for retail investment into tokens offered by Initial DEX Offerings or Initial Decentralized Offerings . Please find further details at https://ethpad.network/.</p>
                        <p style={{ lineHeight: '1.6' }}>"<strong>Gate.io</strong>" is a crypto trading platform both centralized and decentralized. Please find further details at<span>&nbsp;</span><a href="https://www.gate.io/" style={{ color: 'rgb(55, 125, 255)', textDecoration: 'none', transition: 'color 0.2s ease 0s, text-decoration 0.2s ease 0s' }}>https://www.gate.io/</a>.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"KYC"<span>&nbsp;</span></strong>refers to Know-Your-Customer procedure.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"NDO"<span>&nbsp;</span></strong>refers to NFT Decentralized Offering on the NFTLaunch Platform.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"NFT(s)"</strong><span>&nbsp;</span>refer to Non - Fungible Tokens tied to a singular unique Work and built on blockchain, making their codes and proof of ownership immutable. NFTs are intended to simulate real products and values that can be produced and transferred to prove ownership. NFTs may be fungible with other NFTs associated with the same Work while being non-fungible with NFTs associated with different Work. The NFT holders shall not be paid any dividends or revenue sharing for holding it. Unless otherwise specifically agreed between the Creator and the NFT purchaser, the Creator retains the copyright in their Work.&nbsp;</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"NFT Fund"<span>&nbsp;</span></strong>refers to a wallet that receives 5% of the ETH/BNB fees from all tokens being sold. Funds from this wallet will be used to purchase and airdrop fee NFTs to NFTLaunch Members.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>“NFTLaunch Platform”</strong><span>&nbsp;</span>refers to the platform for a deflationary NFT marketplace which is the first platform to offer zero gas wars, fair distribution and free NFT airdrops of new and upcoming Works. Please find further details at<span>&nbsp;</span><a href="https://nftlaunch.network/" style={{ color: 'rgb(55, 125, 255)', textDecoration: 'none', transition: 'color 0.2s ease 0s, text-decoration 0.2s ease 0s' }}>https://nftlaunch.network/</a>.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"NFTLaunch Token(s) or $NFTL"</strong><span>&nbsp;</span>refers to a blockchain - based token which is issued, stored, transferred, transacted by EIP-1559 on the Ethereum Network and BEP-20 on the Binance Smart Chain. Users need to hold NFTLaunch Token to participate in any Services on the Platform including Staking or pre-sales of the Creator’s Work selected for the NFTLaunch Platform.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"NFTLaunch Members"<span>&nbsp;</span></strong>refers to qualified individuals that have passed the KYC and hold NFTLaunch Tokens in an amount that reflects a membership on the NFTLaunch Platform.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>“PancakeSwap”<span>&nbsp;</span></strong>refers to the Decentralized Exchange of the PancakeSwap ecosystem. Please find further details at<span>&nbsp;</span><a href="https://pancakeswap.finance/" style={{ color: 'rgb(55, 125, 255)', textDecoration: 'none', transition: 'color 0.2s ease 0s, text-decoration 0.2s ease 0s' }}>https://pancakeswap.finance/</a>.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Partners"</strong><span>&nbsp;</span>refers to BSCPAD, ETHPAD, PolyPad, ADAPAD, CrossWallet, CrossSwap, and WagyuSwap.</p>
                        <p style={{ lineHeight: '1.6' }}>“<strong>PolyPad”</strong><span>&nbsp;</span>refers to the platform that facilitates token swaps, and where the Project is officially launched, and makes available its native Tokens (“Pool”) to be distributed to users in the allocation rounds.&nbsp; Please find further details at&nbsp;<span>&nbsp;</span><a href="https://polypad.com/" style={{ color: 'rgb(55, 125, 255)', textDecoration: 'none', transition: 'color 0.2s ease 0s, text-decoration 0.2s ease 0s' }}>https://polypad.com/</a>.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Prohibited Jurisdictions"<span>&nbsp;</span></strong>specifically refer to the United States of America (including its territories, and dependencies, and any state of the United States), Albania, Barbados, Botswana, Burkina Faso, Cambodia, Democratic People’s Republic of Korea, Haiti, Iran, Jamaica, Morocco, Myanmar (Burma), Nicaragua, Pakistan, Panama, Philippines, Senegal, South Sudan, Syria, Thailand, Uganda, and Yemen, Zimbabwe.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Services"</strong><span>&nbsp;</span>refer to the services provided to the Users through the Website, which include the participation in the first deflationary NFT marketplace, charity, and launchpad for Creator’s Work with zero gas wars and fair distribution. Services also cover the accessibility to purchase NFT(s) offered from each Work, staking service and other relevant services available through the Website and all free trials made available by the Company, which may be subject to periodic revision.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Staking</strong><span>&nbsp;</span><strong>or Stake"</strong><span>&nbsp;</span>refers to the staking or deposit service in which the Users can delegate or deposit the User’s NFTLaunch Tokens in exchange for a share of staking rewards and other benefits as offered on the Website.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>“UniSwap”<span>&nbsp;</span></strong>refers to the Decentralized Exchange built on DeFi Apps. Please find further details at<span>&nbsp;</span><a href="https://app.uniswap.org/" style={{ color: 'rgb(55, 125, 255)', textDecoration: 'none', transition: 'color 0.2s ease 0s, text-decoration 0.2s ease 0s' }}>https://app.uniswap.org/</a>.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>“WagyuSwap”<span>&nbsp;</span></strong>refers to the Decentralized Exchange built on Velas Blockchain, an entirely new blockchain adapted from Solana which guaranteed full EVM compatibility. Please find further details at<span>&nbsp;</span><a href="https://wagyuswap.app/" style={{ color: 'rgb(55, 125, 255)', textDecoration: 'none', transition: 'color 0.2s ease 0s, text-decoration 0.2s ease 0s' }}>https://wagyuswap.app/</a><span>&nbsp;</span>.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Wallet(s)"<span>&nbsp;</span></strong>refers to a wallet which must be compatible with the Ethereum Network or Binance Smart Chains such as MetaMask or Trust wallet.</p>
                        <p style={{ lineHeight: '1.6' }}><strong>"Work"</strong><span>&nbsp;</span>refers to a digital creation represented by NFTs exclusively minted by the Company’s approved Creator and sold on the NFTLaunch Platform.</p>
                        <p style={{ lineHeight: '1.6' }}><br />
                        </p>
                        <h3>3. General Provisions</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>3.1 Contractual Relationship</span></p>
                            <p style={{ lineHeight: '1.6' }}>These Terms constitute a valid and binding agreement between you and the Company. The binding obligations stipulated in these Terms are enforceable.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>3.2 Revision and Amendments</span></p>
                            <p style={{ lineHeight: '1.6' }}>The Company reserves the right to revise, amend, or update any clauses and provisions stipulated in these Terms in its sole discretion at any time. The Company will notify the revision or amendment of such clauses or provisions by updating these Terms and specify the<span>&nbsp;</span><em>'Last Revised Date</em>' displayed on a page of Terms and Conditions. Any revisions and updates on these Terms will be automatically effective upon their publication on the NFTLaunch Platform, which includes the Website. Therefore, your continuity of accessing or using the Website and/or the Services will be deemed that you agree to have read, understood, and accepted all revised terms and provisions. If you do not agree on any revised or updated terms and provisions, you should immediately stop accessing the Website or using the Services. You are encouraged to frequently and carefully review these Terms to ensure that you understand these Terms.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>3.3 Privacy Policy</span></p>
                            <p style={{ lineHeight: '1.6' }}>You acknowledge and confirm that you have read, understood, and agreed to provisions stipulated in the Company’s Privacy Policy, which will explain how the Company treats your information and protects your privacy when accessing or using the NFTLaunch Platform.</p>
                            <p style={{ lineHeight: '1.6' }}>By using Website, you hereby agree that the Company may collect, use your information, and anonymised data pertaining to your use of the Services for analytics, trends’ identification, and purpose of statistics to further enhance the effectiveness and efficiency of the Services.</p>
                            <p style={{ lineHeight: '1.6' }}>You hereby expressly authorise the Company to disclose any and all information relating to you in the Company’s possession to any law enforcement or government officials upon the request by the court order.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>3.4 Links to and from the Website</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>3.4.1 You may, through hypertext or other computer links, gain access from the Website to websites operated or made available, or otherwise licensed by persons other than us (“<strong>Third Party Services</strong>”). Such hyperlinks are provided for your convenience.</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>3.4.2 A link from Third Party Services does not mean that the Company endorses or approves the content on such a website or does not mean that the Company is an operator of that website. You understand that you are solely responsible for determining the extent to which you may use or rely upon any content at any other Third Party Services websites which you have accessed from the Website. The Company has no control over the content of these sites or resources and accepts no reasonability for them or for any loss or damage that may arise from your use of them.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>3.4.3 The Company assumes no responsibility for the use of, or inability to use, any Third Party Services’ software, other materials, or contents posted and/or uploaded on such a website and we will have no liability whatsoever to any person or entity for any inaccuracy or incompleteness of such third-party content. All intellectual property rights in and to Third Party Services are property of the respective third parties.</p>
                            </blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>3.5 Disclaimer for Accessibility of the Website and the Services</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>3.5.1 Website merely facilitates the NFTLaunch Members accessibility to NFT(s) Work via the NDO and does not provide you with any warranty or representation whatsoever regarding its quality, value, specification, fitness for the purpose, completeness or accuracy of its technology or infrastructure of the Work.</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>3.5.2 The Company will make all commercially reasonable attempts to facilitate information about the Work on the Website. However, you hereby acknowledge that the Company does not guarantee the accuracy, timeliness, or completeness of such information, and does not provide any warranty in connection with your use or reliance on such information. You agree that your use of the Work information will be at all your own risk. The Company will not be liable to you in any manner for the termination, interruption, delay, or inaccuracy of any Work information launched on the Website.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>3.5.3 To the extent permitted by applicable laws, the Website and the Services are provided on an ‘AS IS’ and ‘AS AVAILABLE’ basis. The Company does not warrant that the features, and functions contained on the Website and the Services will satisfy your preferences You hereby agree and acknowledge that your access and use of the Website and the Services are at your own risk, and you will be liable for any responsibility, consequences that may arise out of or in connection with the usage or accessibility of the Website and the Services. You expressly agree that the Company will have absolutely no liability in this regard.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>3.5.4 The Company shall have the right at any time to change or discontinue any or all aspects or features of NFTLaunch Tokens.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>3.5.5 The Company shall have the right, but not the obligation, to monitor the content of the NDOs, to determine compliance with these Terms and any operating rules established by the Company and to satisfy any law, regulation or authorized government request. The Company shall have the right at its sole discretion to edit, refuse to post or remove any content or Work submitted to or posted through the NDOs.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>3.5.6 You hereby agree and acknowledge that the Website may contain links to a third-party website or services that are not owned or even controlled by the Company, thus the Company will hold no responsibility for the content or services, goods, or activities provided by such third-party website. You further agree that the Company has no liability directly or indirectly for any damage, loss, or consequence arising out of or in connection with the usage or reliance of any content, material, services available through the third-party Website.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>3.5.7 The Company reserves the right to limit the availability of the Website to any person, geographic area, or jurisdiction we so desire and/or terminate your access to and use of the Website and the Services, at any time and in our sole discretion.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>3.5.8 The Company may, at our sole discretion, impose limits or restrictions on the use you make of the Website. Further, for commercial, security, technical, maintenance, legal or regulatory reasons, or due to any breach of these Terms, we may withdraw the Website or your access to the Website and the Services at any time and without notice to You.</p>
                            </blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>3.6 Information</span></p>
                            <p style={{ lineHeight: '1.6' }}>You acknowledge that you are solely responsible for any submissions of all Contents, remarks, suggestions, ideas, materials, feedbacks, or other information, including bug reports in relation to the Services provided through the Website including any submission to our social media platforms such as Twitter and Telegram, and you, not us, have full responsibility for such submissions, including their accuracy, legality, reliability, appropriateness, originality, and copyright. We shall reply on the information you have provided and will not verify it. Notwithstanding the foregoing, we have the right to refuse to post, remove, edit, or abridge any submission for any reason and to freely use, copy, disclose, publish, display, or exploit such submission as we deem necessary without any payment of royalty or any copyright infringement, acknowledgement prior to consent, we may retain copies of all information materials relevant to the Services.</p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>4. NFTLaunch’s Intellectual Property</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>4.1&nbsp;All present and future copyright, title, interests in and to the Services, registered and unregistered trademarks, design rights, unregistered designs, database rights and all other present and future intellectual property rights and rights in the nature of intellectual property rights that exist in or in relation to the use and access of the Website and the Services are owned by or otherwise licensed to the Company. Subject to your compliance with these Terms, the Company grants you a non-exclusive, non-sub license, and any limited license to merely use or access the Website and the Services in the permitted hereunder.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>4.2 Except as expressly stated in these Terms, nothing in these Terms should be construed as conferring any right in or license to the Company’s or any other third party’s intellectual rights.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>4.3 If and to the extent that any such intellectual property rights are vested in you by operation of law or otherwise, you agree to do any and all such acts and execute any and all such documents as we may reasonably request in order to assign such intellectual property rights back to us.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>4.4 You agree and acknowledge that all Website Content must not be copied or reproduced, modified, redistributed, used, created for derivative works, or otherwise dealt with for any other reason without being granted a written consent from the Company.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>4.5 Third parties participating on the Website may permit the Company to utilise trademarks, copyrighted material, and other Intellectual Property associated with their businesses. The Company will not warrant or represent that the Content of the Website does not infringe the rights of any third party.</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}><br />
                        </p>
                        <h3>5. Participation in the Services</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>5.1 In order to avail access to the Services or participate in the Services and the NDOs, you may be required to go through KYC/AML/CFT process as requested by each NDO . Please refer to the Identification Verification through KYC/AML/CFT Process in Clause 7 for further information.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>5.2 In terms of Staking, you acknowledge and agree that the Company provides Staking service to Users for the purposes of delegating the User’s NFTLaunch Tokens in exchange for a share of staking rewards and other benefits as offered by the Company.</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>In order to participate in a NDO, you must be staking and passing the KYC/AML/CFT verification process at least 3 hours before the relevant NDO starts. You will provide the staking instruction to the Company. Once the staking instruction is received, the system will record the instruction and you will not be able to cancel or edit the staking instruction. When the NFTLaunch Platform connects with your Wallet, you will see your tier based on the numbers of NFTLaunch Tokens held in your Wallet. The number of NFTLaunch Tokens will affect the number of chances to purchase NFT(s) via the NDO. The Company has neither responsibility nor liability in relation to the allocation of NFT(s) since&nbsp;&nbsp; the allocation will be entirely subject to the Creator.</p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>The Company will be providing deflationary mechanisms into&nbsp; the Website where the first mechanisms will be a 12% fee on all token sales, of which 5% of the ETH/BNB fees will go into a “NFT Fund” wallet, 4% will be rewarded to NFTLaunch Stakers in ETH, 2% of this tax will go to a wallet which will be used for NFTLaunch Tokens buybacks and burns at the Company team’s discretion, and 1% will go to a charity wallet which will be donated on a monthly basis.</p>
                            <p style={{ lineHeight: '1.6' }}>There will also be the second mechanism where the Company provides Users with an early unstaking fee process. Unstaking NFTLaunch Tokens could come at a fee, with the maximum burn fee of up to 20% of Users’ NFTLaunch Tokens. The minimum waiting period for unstaking is 14 days after which no fee will be charged on unstaking.</p>
                            <p style={{ lineHeight: '1.6' }}>The Company reserves the right to reject your participation in Staking, if you fail to satisfy the identity verification requirements through the KYC/AML/CFT process or commit any other suspicious activity while participating in the Services provided by the Company.</p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>5.3 Tokens being stacked are limited to NFTLaunch Tokens only. Once you initiate staking, you will be put in the initial NFTLaunch allocation round of Project which<span>&nbsp;</span><em>currently</em><span>&nbsp;</span>has one NFTStarter tier. In the NFTStarter tier, NFTLaunch Members are required to stake 20,000 $NFTL for receiving a guaranteed allocation to purchase at minimum one NFT or more during each Project, which may be subject to a periodic revision.</span></p>
                            <p style={{ lineHeight: '1.6' }}>Occasionally, there will be times where the Company will airdrop additional NFTs to random NFTLaunch Members.</p>
                            <p style={{ lineHeight: '1.6' }}>You hereby acknowledge and agree that the estimated NFT(s) offered via a NDO or reward yield displayed on the Website for each Work is an estimation. The actual amount of NFT(s) or any reward you will receive may not match or may lower than your estimation which will be subject to the reward compression or expression. You further hereby acknowledge that the Company cannot promise or guarantee the certain amount of the reward receiving from staking your NFTLaunch Tokens in order to engage in a NDO. You shall go through and study the medium article of each NDO and fully understand the significant information and conditions prior to participating in such NDO such as the allocation date, the received NFT(s), the underlying technology, the features and functions of the Work’s NFTs (if available).</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>5.4 In the event that you wish to unstake/withdraw your NFTLaunch Tokens and gain the reward, you will provide the unstaking instruction to the Website. You acknowledge and agree that when you unstake your NFTLaunch Tokens, the delivery of such NFTLaunch Tokens into your Wallet will take twenty one (21) days and the timing may vary based on time of day of the unstake/withdraw instruction and execution. The amount of your unstaking NFTLaunch Tokens will not count towards your tier level for upcoming NDOs.</span></p>
                            <p style={{ lineHeight: '1.6' }}>The Company will levy a withdrawing fee or minimum transaction in the form of ETH or BNB, which will vary and be subject to the amendment only at its sole discretion of the Company periodically. The Company will not be liable for any loss caused or alleged caused by timing differences and economic loss associated with the actual delivery of the NFTLaunch Tokens.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>5.5 The Company reserves the right to amend any terms related to any specific Staking program at any time in its sole discretion. The Company will not be liable for any losses due to your misunderstanding of the Staking program terms and changes to such term’s rules.&nbsp;</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>6. Bridge Service</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>6.1 Upon completion of the registration and identity verification for your Wallet, you may use the services made available by the Partners, including but not limited to, staking, and accessing to other information released by the Company, participating in any launchpad, NFT(s) marketplace, token swaps, bridge, etc., in accordance with the provision of the bridge function (“Bridge Service”).</span></p>
                            <p style={{ lineHeight: '1.6' }}>Under the bridge function, you will be required to conduct the following provisions for the purpose of double verification and convenience crossing between two platforms:</p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>a. Connect your Wallet (by clicking the button shown on the Website); and</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>b. Synchronize the Company KYC information with any launchpad provided by the Partners.</p>
                            </blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>You hereby acknowledge and agree that the Company will not be liable for any damage, loss, consequence, or devaluation of the assets held in any of your Wallets under the provision of Partners’ service, which may occur as a result or in connection with any matter related to disruptive function, broken internet system or any failure.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>6.2 In terms of KYC/AML/CFT synchronisation, you understand and acknowledge that you agree to share your KYC/AML/CFT information among our Partners for the purpose of identity verification through KYC/AML/CFT process. Therefore, you hereby agree that when you use the Company’s synchronize service, you will inform the Company for any KYC/AML/CFT information changed (if any).</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>6.3 Upon sending an instruction of the Company service for KYC/AML/CFT synchronize, your account will be immediately updated to reflect the KYC/AML/CFT profile, and your KYC/AML/CFT profile will be included in Partners’ data to match the identity verification through KYC/AML/CFT requirements. Once the instruction is executed, your account will be updated to reflect that the instruction has been fully matched and passed the KYC/AML/CFT verification and you are ready to experience the Services. The instruction will remain incomplete until it is fully filled with the Wallet address. To provide a Wallet address, you authorised our Partners to confirm and temporarily control the data transfer to the Company.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>6.4 You are only allowed one time to synchronize KYC/AML/CFT to any launchpad provided by Partners. Double check the address input in the box, whether it is the address showing in your Wallet. If you need to synchronize again or update any information, please contact the Company supporting team, details provided in Clause 19.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>6.5 Unless otherwise specified by the Company, to use the Bridge Service, you must complete the identification verification through KYC/AML/CFT process or passed the KYC/AML/CFT synchronized from any Partners for your NFTLaunch’s account. You acknowledge and agree that:</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>a. you fully understand the swapping process, including but not limited to the risk or major fluctuation of digital asset in swapping, and the risk of exacerbated adverse outcome when leverage is used;</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>b. you have sufficient technological knowledge and experience and the capacity to understand the process and agree to independently assume all the risks arising from the Bridge Service; and</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>c. you agree and authorize the Company to take various reasonable measures at its discretion (including but not limited to blocking or freezing the swapping requests under specific circumstances) in accordance with the Company’s decision to protect the legitimate interests of you, the Company itself and other users.</p>
                            </blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>6.6 Prior to conducting the Bridge Service for swapping your asset, in accordance with the type of asset swapping, you shall provide the amount for swapping that shall comply with the daily limit (shown on the BRIDGE page). Unless otherwise specified by the Company, to use Bridge Service, you agree to keep enough assets in your Wallet, as required by engaging in the swapping process. Failure to keep enough assets may result in cancellation of the swapping request.</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>7. Identity Verification through KYC/AML/CFT Process</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>7.1 As a software development company, the Company has no role in enforcing KYC/AML/CFT by default, however, the Company has a mandatory requirement for KYC/AML/CFT identification verification tools for the Creators using NFTLaunch Platform to enforce on their NDO participants. The Company is implementing KYC/AML/CFT tools into NFTLaunch’s launchpad through<span>&nbsp;</span><a href="https://kycaid.com/" style={{ color: 'rgb(55, 125, 255)', textDecoration: 'none', transition: 'color 0.2s ease 0s, text-decoration 0.2s ease 0s' }}>https://kycaid.com/</a>.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>7.2 The use of KYC/AML/CFT tools on the Company by the Creators using the Services on the Website is not the discretion of said entitles, and they must require the NDO participants to complete KYC/AML/CFT verification process before purchasing any NFTs for the Work.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>7.3 Although the Company makes no warranty as to the merit, legality, or juridical nature of any NFT, we nonetheless understand the need of the Creators to require KYC/AML/CFT verification on their NDO Therefore, the Company reserves the right:</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>7.3.1 at any time, to ask for your personal information, Name-Surname, Birthday,-e- mail address, nationality, location, government identification number (Identification Card/Passport number and Date of Identification Card/Passport issuing), telegram username, Wallet address, and any KYC/AML/CFT documentation with the liveness test that it deems necessary to determine the identity and location of a User, and reserves the right to restrict the Services and payment until the User’s identity is sufficiently determined;</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>7.3.2 The liveness test shall require you to take a photo of your government identification with your phone or camera, and then take a photo of (i.e., a selfie of your face) holding your ID document and a piece of paper you wrote on next to your face (not covering your face), in which you shall write down the (1) NFTLaunch , (2) the current date, and (3) the last 4 characters of your Wallet address”.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>7.3.3 to share the submitted KYC/AML/CFT information and documentation to the third parties to verify the authenticity of the submitted information, and the end user (you) giving the consent to share such information by using the Services; and</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>7.3.4 to reject the use of the Services that the Company has the reasonable ground to believe that they are found to be in violation of relevant and applicable AML/CFT laws and regulations, and to cooperate with the competent authorities or any investigation when and if necessary upon the valid request by the court order.</p>
                            </blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>7.4 The Company expressly prohibits and rejects the use of the Services for any form of illicit activity, including money laundering, terrorist financing or trade sanctions violations, consistent with various jurisdictions’ laws, regulations and norms. To that end, the Services are not offered to individuals or entities on any Politically Exposed Persons (“PEP”) lists, or subject to any United States, European Union, or other global sanctions or watch lists. By using the Services, you represent that you are not on any of such lists.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>7.5 You fully acknowledge that your information and KYC/AML/CFT documentation may be disclosed to government agencies or regulators upon a valid request of the court order. Once you have decided to participate in any NDO and start Staking your NFTLaunch Tokens, you must ensure that all information provided to the Company is complete, accurate, and updated in a timely manner. The Company will rely on the information you provided and should there be any reasonable grounds for the Company to believe that the partial or the whole of your information provided to us is incomplete, or incorrect, or outdated, the Company reserves the right to send you a notice to demand correction, or to delete such information directly, and, as the case may be, to disable your access to all or part of the Website and the Services.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>7.6 If the Company has a reasonable ground to believe that any User transacts or uses the Services by using digital currencies derived from any suspicious illegal activities, the Company shall be entitled to freeze, close, or delete the User’s account as necessary. The Company will hold no liability to such Users for any damage, or loss arising out of or in connection with this manner herein. Please note that any attempt to circumvent the Company’s measures set out in this Clause 7 will also result in a similar action.</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <h3>8. NFTLaunch Tokens</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>8.1 The Company will issue a blockchain-based token called “NFTLaunch Token or $NFTL” by EIP-1559 on Ethereum Network and BEP-20 on Binance Smart Chain. NFTLaunch Token is classified as the utility token designed to be used solely on the Website or on<span>&nbsp;</span><a href="https://nftlaunch.network/" style={{ color: 'rgb(55, 125, 255)', textDecoration: 'none', transition: 'color 0.2s ease 0s, text-decoration 0.2s ease 0s' }}>https://nftlaunch.network/</a><span>&nbsp;</span>.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>8.2&nbsp;Users who stake NFTLaunch Tokens in their Wallets will be eligible to participate in a NDO in a guaranteed allocation round using the NFTLaunch Tiered System.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>8.3&nbsp;NFTLaunch Token is not considered as a security of any kind, and it also does not carry any right to vote, manage or the right of ownership in the Website or The Company.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>8.4&nbsp;NFTLaunch Token is neither money nor legal tender/currency, whether fiat or otherwise, and it does not carry any value whether it is intrinsic or assigned.</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>9. Misuse of the Website</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>9.1 In the event of any misuse and/or abuse of the Website or breach of any provision in these Terms, the Company reserves the right to block your access to the Website and other Services until the matter is solved.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>9.2 Use of the Website for transmission, publication or storage of any material on or via the Website which is in violation of any applicable laws or regulations or any third-party’s rights is strictly prohibited, including but not limited to the use of the Website or the transmission, distribution, publication or storage any material on or via the Website in a matter or for the purpose which infringes copyright, trademark, trade secret or other intellectual property rights, is obscene or harmful to minors or constitutes an illegal act or harassment, is libellous or defamatory, violates any privacy or data protections laws, is fraudulent or breaches any exchange control laws.</span></p>
                        </blockquote>
                        <h3>10. Representations and Warranties of the Users</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>10.1&nbsp;You hereby agree to make the following representations and warranties by accessing the Website and/or using the Services:</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>10.1.1 You have full capacity and authority under the applicable laws to agree and bind yourself to these Terms.</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>10.1.2 You are eighteen years of age or older.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>10.1.3 You are not a citizen or a resident of the Prohibited Jurisdictions, and you do not have any relevant connection with any of the Prohibited Jurisdictions.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>10.1.4 You are aware and agree to comply with all applicable domestic and international laws, statutes, ordinances, and regulations applicable to your use of the Website and the Services. Your use of the Website and the Services are not for any unlawful or illegal purposes, including but not limited to the usage against the copyright laws and AML/CFT laws.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>10.1.5 You are the exclusive owner of NFTLaunch Tokens and your other cryptocurrencies, including ETH, and BNB held in your Wallet. The tokens maintained in your Wallets are not and will not be derived from money laundering, terrorist financing, fraud, or any other illegal activities under any applicable laws. You further hereby acknowledge and agree that the Company will not be responsible for actions taken by you that result in the loss or destruction of the value of the NFT(s), the tokens, and rewards you hold in the Wallet.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>10.1.6 You validly undertake any action or enter into any transaction with regard to these Terms. You are solely responsible for the use of the Website and the Services for all activities, or transactions that occur on or through your User account on NFTLaunch.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>10.1.7 You will provide only accurate, complete, and up-to-date information and documents, if any, for the purposes of accessing or using or participating in the Services on the Website. You will further agree to put your effort to ensure that the confidentiality of your personal or credential information, including your Wallet address is restricted, and safely maintained to your device you use to access the Website.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>10.1.8 You acknowledge and agree that if you lose access to the Wallet that you connected with NFTLaunch, the Company will not be able to help you recover the loss, or transfer of NFT(s) or any NFTLaunch Token back to your Wallet. It will be your sole responsibility to manage your NFTLaunch account and the private key.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>10.1.9 You will be responsible for obtaining the data network access necessary to use the Website. Your network's data and rates and fees may apply if you access or use the Website from a wireless-enabled device, and you will be responsible for such rates and fees.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>10.1.10 You understand and are aware of the risks associated with accessing or using or participating in the Services, and you will be fully liable at your own risk.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>10.1.11 You are aware that you are subject to tax regulations in the jurisdiction you reside in and will be fully responsible for filling or reporting any taxes and paying them as required by the Applicable Laws. the Company will not be liable to compensate you for your tax obligations or advise you in relation to your tax obligations. Any uncertainties and unpredictable matters in tax legislation with respect to any NFT(s) or any token may expose you to any unknown or unforeseeable tax implications associated with your holding of NFT(s) or any token and the use of the Services for which the Company will have no liability. Moreover, you will not hold the Company liable for any expenses or losses resulting from unknown or unforeseeable tax implications.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>10.1.12 You will not breach any of the provisions stipulated in these Terms, the Privacy Policy, or any Applicable Laws in any relevant jurisdictions.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>10.1.13 You will not use the Website and the Services in one of any following manners, except as expressly permitted in these Terms, or at the Company’s discretion.</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>You will not:</p>
                            </blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>a. infringe any property rights, including but not limited to copyrights, patents, trademarks, or trade secrets of the Company;</p>
                        </blockquote></blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>b. use the Website or the Services to transmit any data or send or upload any material or content that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware, ransomeware, or any other harmful programmes or similar computer code designed to adversely affect the operation of the Website and/or the Services;</p>
                        </blockquote></blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>c. expressly or impliedly, use the Website and the Services in the manner that is deemed unlawful, offensive, malicious, threatening, libellous, defamatory, obscene, or otherwise objectionable or violates these Terms, or any other party's intellectual property;</p>
                        </blockquote></blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>d. modify, make any back-up or archival copies of the Platform or any part thereof including disassembling, and you will also not adapt, hack the Website or modify another website to falsely imply that it is associated with the Website;</p>
                        </blockquote></blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>e. crawl, scrape, or otherwise cache any content from the Website, and you agree not to use any automated data collection methods, data mining, robots, or scraping or any data gathering methods of any kind on the Website; and</p>
                        </blockquote></blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>f. use the Website or any of its contents for advertising or soliciting, for any other commercial, political, or religious purposes, or to compete, either directly or indirectly with the Company.</p>
                        </blockquote></blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>10.1.14 You will defend, indemnify, and not hold the Company, the Partners, its affiliates, each of its respective employees, officers, directors, and representatives liable to and against any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorney's fees), arising out of or relating to any third-party claim concerning these Terms, or your use of the Services in violation of these Terms and applicable laws.</p>
                        </blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>10.2 Each of the User’s representations and warranties under this Clause 10 shall survive and continue to remain in full force and effect after the termination and/or the expiration of these Terms.</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>11. Representations and Warranties of the Creators</h3>
                        <p style={{ lineHeight: '1.6' }}>By listing their NFTs on the NFTLaunch Platform, the Creators:</p>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>11.1 expressly represent and warrant that their Work is an original creation. Creators are prohibited from listing Works consisting of unlicensed or unauthorized copyrighted content, including any imagery, design, audio, video, human likeness, or other unoriginal content not created by the Creator, not authorized for use by the Creator, not in the public domain, or otherwise without a valid claim of fair use. Creators can only list the works that they personally created and must refrain from listing works to which copyright ownership is unknown or disputed (i.e., commissioned works or works made for hire);</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>11.2 expressly represent and warrant that their Works contain only original artistic content otherwise authorized for use by the Creator. To the extent a Work contains unoriginal content, including content from any other person, the Creator represents and warrants that it has permission to incorporate such unoriginal content;</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>11.3 expressly represent and warrant that the sale, display or performance of their NFTs on the NFTLaunch Platform is not a violation of any agreement, contract, or obligation owed to a third party;</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>11.4 shall not establish a price of NFTs which does not reflect the true state of the market of the relevant Work; and</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>11.5 agrees to indemnify the Company and be held liable for any claim against the Company arising out of the Creator’s breach of these Terms. Creator releases and discharges the Company from any damages or causes of action resulting from a sale of any of the Creator’s listed NFTs.</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>12. Prohibited Uses of the NDOs</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>12.1 Users must not, directly or indirectly:</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>12.1.1 employ any device, scheme or artifice to defraud, or otherwise materially mislead the Company, the Partners or any users of the NFTLaunch Platform, including impersonating or assuming any false identity;</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>12.1.2 engage in any act, practice or course of business that operates or would operate as fraud or deceit upon the Company, the Partners or any users of the NFTLaunch Platform; or</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>12.1.3 participating in, facilitating, assisting or knowing transacting with any pool, syndicate or joint account organized for the purpose of unfairly or deceptively influencing the market price of a Work; or</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>12.1.4 commit any violation of applicable laws, rules or regulations.</p>
                            </blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>12.2 In addition to Clause 12.1 above, the Creators must not directly or indirectly:</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>12.2.1&nbsp;utilize the NDOs to transact in securities, commodities futures, trading of commodities on a leveraged, margined or financed basis, binary options (including prediction-market transactions), real estate or real estate leases, equipment leases, debt financings, equity financings or other similar transactions;</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>12.2.2 utilize the NDOs to sell or advertise personal, professional or business services; or</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>12.2.3 utilize the NDOs primarily as a general-purpose communication or messaging or social networking platform</p>
                            </blockquote></blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>13. Removing Creators from the NFTLaunch Platform</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>13.1 Failure to comply with these Terms and/or any of the the Company rules or violation of applicable laws, rules or regulations may result in, without limitation, suspension or termination of the User’s account and other permissions to the NFTLaunch Platform and the Website, and/or monetary damages. The Company has the sole authority and discretion to remove, suspend, or revoke the User’s access to the NFTLaunch Platform.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>13.2 Failure to comply with these Terms and/or any of the Company rules, or violation of applicable laws, rules or regulations may result in, without limitation, suspension or deletion of the Creator’s account, termination of the Creator’s NDOs on the NFTLaunch Platform, delisting of the Creator’s NFTs on the NFTLaunch Platform and limitation, suspension or termination of other permissions to the NFTLaunch Platform and the Website, and/or monetary damages. The Company has the sole authority and discretion to remove, suspend, or revoke the Creator’s access to the NFTLaunch Platform.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>13.3 The Company reserves the right to terminate the accounts, block usage of the relevant NFTs and/or delist the relevant NFTs of any Creator who is a repeat infringer or who is repeatedly charged with copyright infringement.</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>14. Takedown Notices for Copyright Infringement Claims</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>14.1 Subject to this Clause 13, the Company will respond to notices of all alleged copyright infringement under the applicable laws. If a User or any other person believes that their intellectual property rights have been infringed, please notify us immediately. For the Company to respond, the notice must provide the following information:</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>&nbsp;14.1.1 a physical or electronic signature of the copyright owner or of a person authorized to act on behalf of the copyright owner;</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>&nbsp;14.1.2 a clear identification of the copyrighted work that is claimed to be infringing;</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>&nbsp;14.1.3 identification of the Work that is claimed to be infringing, including, if possible, a URL representing a link to the alleged Work on the Website;</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>&nbsp;14.1.4 information sufficient to permit the Company to contact the complainant, such as email address, physical address, and/or telephone number;</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>&nbsp;14.1.5 a statement that the complainant has a good-faith belief that the relevant Work is being used in a way that is not authorized by the copyright owner or under the applicable law; and</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>&nbsp;14.1.6 a statement that the above information is accurate, and the complainant is the copyright owner or is authorized to act on behalf of the copyright owner.</p>
                            </blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>&nbsp;14.2 Users may submit their takedown notices using the contact information provided in Clause 20.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>&nbsp;14.3 If a Creator believes that a Creator’s Work has been removed by mistake or misidentification, the Creator may provide the Company with a written counter-notification containing the following information:</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>14.3.1 the Creator’s name, address, telephone number, and email address;</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>14.3.2 a description of the Work that was removed, along with the URL where the Work was posted in the NDO prior to its removal;</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>14.3.3 the following statement: “I swear that I have a good-faith belief that the Work was removed or disabled due to a mistake or misidentification of the Work to be removed or disabled”; and</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>14.3.4 the Creator’s electronic or physical signature.</p>
                            </blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>14.4 Creators may submit their takedown notices using the contact information provided in Clause 20.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>14.5 Upon receipt of a valid counter notification, the Company will forward it to the party who submitted the original takedown notice. The original party (or their representative) will then have ten (10) days to notify us that they have filed legal action relating to the allegedly infringing Work. If the Company does not receive any such notification within ten (10) days, the Company may restore the alleged Work to the NDO.</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>15. Risk Disclosure</h3>
                        <p style={{ lineHeight: '1.6' }}>By accessing the Website or using or participating in the Services, you expressly acknowledge and assume the following risks:</p>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>15.1 Risk of loss in value</span></p>
                            <p style={{ lineHeight: '1.6' }}>NFT(s), tokens or any digital currencies are not issued by any central banks or national, supra-national, or quasi-national organizations. They are also not backed by any hard assets or other credits. The value of NFT(s), and tokens or any digital currencies is affected by several factors, including but not limited to, the total number of NFT(s) or any digital currencies in existence, the continued willingness of market participants to exchange government-issued currency for tokens or digital currencies , purchasers' expectations with respect to the rate of inflation of fiat currencies, purchasers' expectations with respect to the rate of deflation of cryptocurrencies, interest rates, currency exchange rates, cyber theft of cryptocurrencies from online digital wallet providers, or news of such theft from such providers or individuals' digital wallets, investment and trading activities of large investors, monetary policies of the governments, trade restrictions, currency devaluations and revaluations, regulatory measures, the global or regional political, economic or financial events and situations. Thus, all these factors will affect the value of NFT(s), the tokens or digital currencies, which may result in the permanent partial or total loss of the value of the NFTs, a particular token or digital currency. No one will be obliged to guarantee the liquidity or the market price of any of the NFTs, the tokens or digital currencies maintained into your Wallets. The volatility and unpredictability of the value of tokens or digital currencies relative to the government-issued currency may result in a significant loss over a short period of time.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>15.2 The regulatory regime governing NFT(s), tokens or digital currencies</span></p>
                            <p style={{ lineHeight: '1.6' }}>The regulatory framework relating to NFT(s), tokens or digital currencies remains unsettled, and any laws, regulations, or guidelines may be significantly revised and amended which will materially and adversely affect the value of NFT(s), tokens or digital currencies and your services on https://nftlaunch.network/.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>15.3 Technical and system failure affected the obligations stipulated in these Terms</span></p>
                            <p style={{ lineHeight: '1.6' }}>the Company may experience system failures, unplanned interruptions in its Ethereum Network, the Services, Binance Smart Chain Network, hardware or software defects, security breaches or other causes that could adversely affect the Company infrastructure network, which includes the Website and the Services.</p>
                            <p style={{ lineHeight: '1.6' }}>The Company is unable to anticipate the occurrence of hacks, cyber-attacks, mining attacks, including but not limited to double-spend attacks, majority mining power attacks and selfish-mining attacks, distributed denial of service attacks or errors, vulnerabilities or defects on the Website, NFTLaunch Tokens, Users' Wallets or any technology, including but not limited to smart contract technology. Also, the Company is unable to detect the hacks as mentioned earlier, mining attacks, cyber-attacks, distributed denials of service errors, vulnerabilities, or defects in a timely manner and does not have sufficient resources to efficiently cope with multiple service incidents happening simultaneously or in rapid succession.</p>
                            <p style={{ lineHeight: '1.6' }}>In addition, the Company's network or the Services could be disrupted by numerous events, including natural disasters, equipment breakdown, network connectivity downtime, power losses, or even intentional disruptions of its services, such as disruptions caused by software viruses or attacks by unauthorized users, some of which are beyond the Company's control. Although the Company has taken steps and used its best endeavour against malicious attacks on its appliances or its infrastructure, which are critical for the maintenance of the Website and the Services, there can be no assurance that cyber-attacks, such as distributed denials of the Services, will not be attempted in the future, and that the Company's enhanced security measures will be effective. Any significant breach of the Company's security measures or other disruptions resulting in a compromise of the usability, stability and security of the Company's network or the Services may adversely affect NFTLaunch Tokens.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>15.4 Please note that the Company does not store, send, or receive any NFTLaunch Tokens and NFTs on the Services. NFTLaunch Tokens and NFTs exist only by virtue of ownership record maintained on its supporting blockchain. Hence, any transfer of NFTLaunch Tokens or NFTs shall occur only within the supporting blockchain and not on the Services of the Company.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>15.5 In the event that NFTLaunch Tokens or NFTs held by you become inaccessible on the NFTLaunch Platform, such event shall not constitute grounds for any claim against the Company.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>15.6 The Company will have no liability for any delay, error, interruption, or failure to perform any obligation under these Terms where the delay or failure is directly or indirectly resulting from any causes beyond the Company's control, including, but not limited to:</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>15.6.1 Acts of God, nature, court, or government;</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>15.6.2 Failure or interruption of public or private telecommunication networks, the failure of Ethereum or Binance Smart Chain Network, communication channels or information systems;</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>15.6.3 Acts or omission of a party for whom the Company is not responsible;</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>15.6.4 Delay, failure, or interruption in, or unavailability of third-party services; and</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>15.6.5 Strikes, lockouts, labour disputes, wars, terrorist acts and riots.</p>
                            </blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>15.7 YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES AND THE WEBSITE IS COMPLETELY AT YOUR OWN RISK. THIS CLAUSE IS NOT EXHAUSTIVE AND DOES NOT DISCLOSE ALL THE RISKS ASSOCIATED WITH NFTS, TOKENS, DIGITAL CURRENCIES AND THE USE OF SERVICES. THEREFORE, YOU ARE RECOMMENDED TO CAREFULLY CONSIDER WHETHER SUCH USE IS SUITABLE FOR YOU IN LIGHT OF YOUR JUDGEMENT, CIRCUMSTANCES, AND FINANCIAL POSITION.</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>16. Limitation of Liability</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>16.1&nbsp;NOTWITHSTANDING ANY PROVISIONS WITHIN THESE TERMS, IN NO EVENT WILL NFTLAUNCH, ITS PARTNERS, ITS AFFILIATES, OR ITS EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE TO THE USER FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR OTHER SUCH WEBSITES, OR ANY OTHER SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, OR OTHER SUCH WEBSITES, INCLUDING, BUT NOT LIMITED TO, LOST REVENUE, LOST PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, LOSS OF YOUR CREDENTIAL INFORMATION, LOSS OR INTERRUPTION OF TECHNOLOGY, LOSS OF USE OF SERVICE OR EQUIPMENT, EVEN IF THE USER WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND WHETHER ARISING UNDER A THEORY OF CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE. NFTLAUNCH WILL ALSO HAVE NO LIABILITY FOR ANY EMERGENCY AND/OR UNFORESEEABLE INCIDENTS RELATED TO YOUR USE OF THE SERVICES ON THE WEBSITE SUCH AS STOLEN PRIVATE KEY, OR HACKED ACCOUNTS.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>16.2 Except as expressly provided in these Terms, and to the maximum extent permitted by any Applicable Laws, we disclaim all other representations or warranties, express or implied, made to you, your affiliates, or any other person, including, without limitation, any warranties regarding the quality, suitability, merchantability, fitness for a particular purpose or otherwise (regardless of any course of dealing, custom or usage of trade) of any service provided incidental to the Services under these Terms.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>16.3 In no event will our aggregate liability for any loss or damage that arises in connection with the Services exceed the purchase amount you paid to participate in the Services, if any, during a twelve (12) month period immediately preceding the event that gave rise to the claim for liability. The preceding limitations of liability will apply to the fullest actual amount you paid to participate in or access the Services.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>16.4 Except as expressly provided in these Terms, and to the fullest extent permitted by any Applicable Laws, the Company, its Partners, its affiliates, and its related parties each disclaim all liability to you for any loss or damage arising out of or due to:</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>16.4.1 your use of, inability to use, or availability or unavailability of the Services, including any Third Party Services made available through the Services;</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>16.4.2 the occurrence or existence of any defect, interruption, deletion of files, delays in the operation or transmission of information to, from, or through the Services, communications failure, theft, destruction or unauthorised access to the Company’s records, programs, services, server, or other infrastructure relating to the Services;</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>16.4.3 the Services being infected with any malicious code or viruses; or</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>16.4.4 the failure of the Services to remain operational for any period of time.</p>
                            </blockquote></blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>17. Indemnification</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>17.1 You irrevocably undertake the responsibility of fully indemnifying and holding harmless each of the Company, its Partners, its affiliates, licensors, shareholders, officers, directors, managers, employees, and agents from and against any and all losses, claims, actions, proceedings, damages, demands, judgements, sums, liabilities, damages, costs, charges and expenses, including, but not limited to, any reasonable attorney's fees or penalties imposed by any regulatory authority, and reimbursements arising out of or related to the following situations:</span></p>
                        </blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}>17.1.1&nbsp;Your use or any person using the Services on your behalf or participation in accordance with the Services on the Website;</p>
                        </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>17.1.2 Your breach of or our enforcement of these Terms;</p>
                            </blockquote><blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                                <p style={{ lineHeight: '1.6' }}>17.1.3 Any violations of Applicable Laws, regulation, or rights of any third-party during your use or participation in the Services.</p>
                            </blockquote></blockquote>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>17.2 If you are obligated to indemnify the Company, its Partners, its affiliates, shareholders, licensors, officers, directors, managers, employees, and agents, the Company will have the right, at its sole discretion, to control any action or proceeding and to determine whether the Company wishes to proceed, or settle, and if so, on what terms or provisions.</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>18. Termination</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>18.1 These Terms will be immediately terminated by discontinuing your use of or participating in the Services and you agree to terminate the accessibility on the NFTLaunch’s platform.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>18.2 These Terms can be suspended or terminated without a notice from the Company if there is a reasonable ground for the Company to believe that you have breached any of the terms or provisions stipulated in these Terms, or if you do not comply with these Terms.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>18.3 The termination of these Terms will not prevent the Company from seeking remedies from you in the case where you have breached any terms or provisions of these Terms before such termination. The Company will not be liable to you or to any third party for any termination, suspension, or modification of your access to the Services.</span></p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>18.4 Any ongoing obligation to you as well as the provisions regarding (i) the Company's Intellectual Property, (ii) No solicitation or Offering, (iii) Indemnification, (iv) Limitation of liability, and (v) any other provisions designed to survive, will survive any termination or expiration of these Terms for any reason.</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>19. No Financial and Legal Advice</h3>
                        <p style={{ lineHeight: '1.6' }}>The Company is merely a technology platform, and is not your broker, intermediary, agent, or legal advisor and has no fiduciary relationship or obligation to you in connection with any decisions or activities affected by you using the Website or the Services. No communication or information provided to you by the Company is intended as or will be considered or construed as, the solicitation of an offer to buy, the investment advice, financial advice, legal advice, or any other sort of advice. All Services, transactions, and investments will be executed automatically based on the parameters of your consideration. You will be solely responsible for determining whether any Services, or investments are suitable and match your interests according to your judgement, objectives, circumstances, and risk tolerance. You will be solely responsible for any losses or liabilities therefrom.</p>
                        <p style={{ lineHeight: '1.6' }}>Before executing any transactions, purchasing NFTLaunch Tokens or NFT(s)on the Website, you should consult with your independent financial, legal, or tax professionals. The Company will not be liable for the decisions you make to access and purchase through the Company.</p>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>20. Notice/Announcement</h3>
                        <p style={{ lineHeight: '1.6' }}>Any notice, requests, demands, and determinations for the Company under these Terms (other than routine operational communications) shall be sent to<span>&nbsp;</span><a href="mailto:support@nftlaunch.network" style={{ color: 'rgb(55, 125, 255)', textDecoration: 'none', transition: 'color 0.2s ease 0s, text-decoration 0.2s ease 0s' }}>support@nftlaunch.network</a><span>&nbsp;</span>at<span>&nbsp;</span><a href="https://nftlaunch.network/" style={{ color: 'rgb(55, 125, 255)', textDecoration: 'none', transition: 'color 0.2s ease 0s, text-decoration 0.2s ease 0s' }}>https://nftlaunch.network/</a><span>&nbsp;</span>Website.</p>
                        <p style={{ lineHeight: '1.6' }}><br />
                        </p>
                        <h3>21. Governing Law, Resolving Disputes, Arbitration and Class Action Waiver</h3>
                        <p style={{ lineHeight: '1.6' }}>PLEASE READ THIS SECTION CAREFULLY AS IT INVOLVES A WAIVER OF CERTAIN RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING A CLASS ACTION.</p>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>21.1 Notice of Claim and Dispute Resolution Period</span></p>
                            <p style={{ lineHeight: '1.6' }}>Please contact the Company first. The Company will seek to address your concerns without resorting to formal legal proceedings whenever possible. If you have a dispute with the Company, you should contact the Company, and a case number will be assigned. The Company will attempt to resolve your dispute internally as soon as possible. The parties will agree to negotiate in good faith to resolve the dispute and discussions will remain confidential and subject to applicable laws protecting settlement discussions from use as evidence in any legal proceeding.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>21.2 Agreement to Arbitrate</span></p>
                            <p style={{ lineHeight: '1.6' }}>You and the Company agree that subject to Clause 21.1 above, any dispute, claim, or controversy between you and the Company that arises in connection with, or relating in any way, to these Terms, or to your relationship with the Company as an User of the Services (whether by contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims arise during or after the termination of these Terms) will be determined by a mandatory final and binding individual arbitration rather than a class action, except as set forth below under Exceptions to the Agreement to Arbitrate. You and the Company further agree that the arbitrator will have the exclusive power to rule on his or her jurisdiction, including, without limitation, any objections with respect to the existence, scope, or validity of the Agreement to Arbitrate, or to the arbitrability of any claim or counterclaim. Arbitration is more informal than a lawsuit in court. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. There may be more limited discovery than in court. The arbitrator must follow this agreement and can award the same damages and relief as a court, including, if applicable, attorney fees, except the arbitrator may not award declaratory or injunctive relief benefiting anyone but the parties to the arbitration. The arbitration provisions set forth in this Clause 21 will survive termination of these Terms.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>21.3 Arbitration Rules</span></p>
                            <p style={{ lineHeight: '1.6' }}>Any dispute arising out of or in connection with these Terms including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre (“<strong>SIAC</strong>”) in accordance with the Arbitration Rules of the Singapore International Arbitration Centre ("<strong>SIAC Rules</strong>") for the time being in force, which rules are deemed to be incorporated by reference in these Terms. The seat of the arbitration shall be Singapore. The Tribunal shall consist of one arbitrator. The language of the arbitration shall be English.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>21.4 Governing Law/Jurisdiction</span></p>
                            <p style={{ lineHeight: '1.6' }}>The governing law of the arbitration will be that of the Republic of Seychelles.&nbsp;</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>21.5 Confidentiality</span></p>
                            <p style={{ lineHeight: '1.6' }}>The parties agree that the arbitration will be kept confidential. The existence of the arbitration, any non-public information provided in the arbitration, and any submissions, orders or awards made in the arbitration will not be disclosed to any non-parties except the tribunal, the parties, their counsel, experts, witnesses, accountants and auditors, insurers and reinsurers, and any other person necessary to facilitate the arbitration. Notwithstanding the preceding, a party may disclose information to the extent that disclosure may be required to fulfil a legal duty, protect, or pursue a legal right, or enforce or challenge an award in bona fide legal proceedings. This confidentiality provision will survive the termination of these Terms and any arbitration brought under these Terms.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>21.6 Class Action Waiver</span></p>
                            <p style={{ lineHeight: '1.6' }}>You and the Company agree that any claims relevant to these Terms, or your relationship with the Company will be brought against the other party in arbitration on an individual basis only and not as a plaintiff or class member in a purported class or representative action. You and the Company further agree to waive any right for such claims to be brought, heard, or arbitrated as a class, collective, representative, or private attorney general action, to the extent permissible by applicable laws. Combining or consolidating individual arbitrations into a single arbitration is not permitted without the consent of all parties involved.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>21.7 The Company reserves the right to update, modify, revise, suspend, or make future changes to Section 21.2 regarding the parties' Agreement to Arbitrate, subject to applicable laws. You hereby consent and agree that it is your responsibility to ensure that your understanding of this Clause is up to date. Subject to applicable laws, your continued use of your NFTLaunch account will be interpreted as your acceptance of any modifications to Clause 21 regarding the parties' Agreement to Arbitrate. You agree that if you object to the modifications to Clause 21, the Company may block access to your account pending closure of your account. In such circumstances, these Terms prior to modification will remain in full force and affect the pending closure of your accessibility.</span></p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <h3>22. Miscellaneous</h3>
                        <blockquote style={{ border: 'none', margin: '0px 0px 0px 40px' }}>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>22.1&nbsp;Severability</span></p>
                            <p style={{ lineHeight: '1.6' }}>If any of the provisions in these Terms is found by a court of competent authority to be invalid, void, unlawful or unenforceable under any applicable laws, such unenforceability or invalidity will not render these Terms unenforceable or invalid as a whole, and such provisions will be deleted without affecting the remaining provisions herein.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>22.2 Variation of Terms</span></p>
                            <p style={{ lineHeight: '1.6' }}>The Company has the right to revise these Terms at our sole discretion at any time, and by using the Website or other NFTLaunch’s platforms, you will be expected to review such Terms regularly to ensure that you understand all provisions stipulated in these Terms.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>22.3 Assignment</span></p>
                            <p style={{ lineHeight: '1.6' }}>The Company will be allowed to assign, transfer, and subcontract Our rights and/or obligations under these Terms without the need to provide you any notification or acquire your consent. Nevertheless, you will not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>22.4 Entire Agreement</span></p>
                            <p style={{ lineHeight: '1.6' }}>These Terms, including the Privacy Policy and any rules contained on the Website or others Platform, constitute the sole and entire agreement between you and the Company with respect to your use of the Services, and supersedes other prior or contemporaneous negotiations, discussions, agreements, understandings, representations, and warranties, both written and oral, between you and the Company with respect to such subject matter.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>22.5 No Third-Party Rights</span></p>
                            <p style={{ lineHeight: '1.6' }}>Nothing in these Terms will be deemed to create any rights to any creditors or other persons, not a party hereto. Moreover, these Terms will not be construed, in any respect, to be a contract, in whole or in part, for the benefit of any third parties.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>22.6 Clickwrap</span></p>
                            <p style={{ lineHeight: '1.6' }}>The Company may deliver the Services through electronic means such as download links, graphical, Tools or other technologies for providing the Services for users. The user interfaces to such electronic means may require that the users agree to these Terms by checking a box, clicking a button, or continuing with the Services. If user through such action the user becomes a Party to these Terms. Such an action of acceptance shall be sufficient to bind the users to the terms and conditions herein these Terms.</p>
                            <p style={{ lineHeight: '1.6' }}><span style={{ fontWeight: 'bold' }}>22.7 Waiver</span></p>
                            <p style={{ lineHeight: '1.6' }}>The failure of one Party to require the performance of any provision will not affect that Party's right to require performance at any time thereafter. At the same time, the waiver of one Party to seek recovery for the other Party's violation of these Terms of any provisions of applicable terms will not constitute a waiver by that Party of any subsequent breach or violation by the other Party or of the provision itself.</p>
                        </blockquote>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                        <p style={{ lineHeight: '1.6' }}>&nbsp;</p>
                    </div>

                </div>
            </div>
        </Container>
        <Container className="mt-5">
            <Footer />
        </Container>
    </>
}
export default TermOfUsePage;