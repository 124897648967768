import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCloseProjects } from "../../../hook/useState";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ClosedProjectsComponent = () => {
  const [tempLoading, setTempLoading] = useState([]);
  const closedProjects = useCloseProjects();

  return (
    <>
      <section id="welcome" className="section mb-5">
        {/* <div className="wrapper">
          <div className="welcome__wrapper text-center">
            <img src="/images/closed.png" style={{ maxHeight: '130px', maxWidth: '100%' }} />
          </div>
        </div> */}
        <h2 className="section-title text-center">Projects Closed</h2>
      </section>
      <div className="pp-investment pp-close">
        {/* <img src="/images/drone.png" width="350" className="position-absolute d-none d-xl-block" style={{ left: '50%', transform: 'translateX(-50%)', bottom: '0px' }} /> */}
        <div className="text-center">
          <div className="investment-section-items">
            <div className="row gx-md-5">
              {closedProjects.length > 0 ? closedProjects.map((item, key) => {
                return (
                  <div className="col-xl-4 col-lg-6 col-md-6 text-center" key={key}>
                    <div className="level-item bordered project-item mb-4 mt-xl-3 staking-card style-3">
                      <div className="bordered__inner bordered--double_angled bordered--bg_top">
                        <div className="level-item__title-wrap">
                          <div className="level-item__title title my-3">
                            <span className="pp-status-closed">Closed</span>
                            <span className="symbol">{item.symbol}</span>
                          </div>
                        </div>
                        <div className="level__inner pb-0 pt-3">
                          <Link to={(item.contract && item.contract !== 'TBA') ? `projects/${item.contract}` : `#`} className="text-decoration-none w-100 px-4">
                            <div className="level-item__img mb-4">
                              <img src={item.logo} alt="" />
                              {
                                item.athMultiplier &&
                                <div className="pp-top-tag">
                                  <div><small>ATH</small></div>
                                  <div><b>{item.athMultiplier}x</b></div>
                                </div>
                              }
                            </div>
                            <div className="level-item__digit title">{item.name}</div>
                            <div className="level-item__info pb-4 px-2 text-start mt-2">
                              <div className="text-center mb-4 level-item__desc">
                                {item.description}
                              </div>
                              <ul className="mb-3">
                                <li>
                                  <span>Swap rate</span><br />
                                  <b className="nowrap text-white">
                                    1 {item.symbol} = {(item["rate"])} {item["projectTokenSymbol"]}
                                  </b>
                                </li>
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                      <span>Cap</span><br />
                                      <b className="nowrap text-white">
                                        {`${item.maxTotalParticipationAllocated || 0} ${item.symbol || ""}`}
                                      </b>
                                    </div>
                                    <div className="pe-3">
                                      <span>Access</span><br />
                                      <b className="nowrap text-white">
                                        {item.isPrivate ? "Private" : "Public"}
                                      </b>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className={item.state == 'O' ? 'pp-card-progress-wrap disabled' : 'pp-card-progress-wrap'}>
                                    <div className="mb-2 d-flex justify-content-between align-items-center pp-card-progress-top">
                                      <div className="pp-card-col">Progress</div>
                                      {item.state != 'O' && <div className="pp-card-col text-end">Participants <b className="text-white">{item['totalCountUserParticipated']}</b></div>}
                                    </div>
                                    <div className='pp-card-progress'>
                                      {(!tempLoading || tempLoading.length <= 0) && <div title="9" className="pp-card-progress-percent" style={{ width: `${(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100}%` }}></div>}
                                      {tempLoading && tempLoading.length > 0 && tempLoading.map((progress, idx) => {
                                        return (<div key={idx} title="0" className="pp-card-progress-percent animation" style={{ width: progress.width + '%', left: progress.left + '%' }}></div>)
                                      })}
                                      <div className="pp-card-progress-label">
                                        <span><b>{((item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                        {item.state == 'O' && <span className="participants-center"><b className="">{item['totalCountUserParticipated']}</b> Participants</span>}
                                        <span className="text-allocation"><b className="">{item.totalFundParticipated.toFixed(4)}/{item.maxTotalParticipationAllocated}</b></span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Link>

                          <div className="item-social">
                            {
                              item.pancakeswap &&
                              <a style={{ backgroundColor: '#47d4dc' }} href={item.pancakeswap} target="_blank"><img height="18" src="/images/pancake-swap.png" /></a>
                            }
                            {
                              item.website &&
                              <a href={item.website} target="_blank"><i className="fas fa-globe"></i></a>
                            }
                            {
                              item.twitter &&
                              <a href={item.twitter} target="_blank"><i className="fab fa-twitter"></i></a>
                            }
                            {
                              item.medium &&
                              <a href={item.medium} target="_blank"><i className="fab fa-medium-m"></i></a>
                            }
                            {
                              item.telegram &&
                              <a href={item.telegram} target="_blank"><i className="fab fa-telegram"></i></a>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="bordered__icon bordered__icon--left">
                        <img src="/images/bordered-lines.svg" alt="" />
                      </div>
                      <div className="bordered__icon bordered__icon--right">
                        <img src="/images/bordered-lines-narrow.svg" alt="" />
                      </div>
                    </div>
                  </div>
                )
              })
                :
                <span className="text-white fs-mb-16" style={{ fontSize: '24px' }}>
                  No projects currently closed
                </span>
              }
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default ClosedProjectsComponent;
