import React from "react";
import moment from 'moment';

const ScheduleTabComponent = (props) => {
  return (
    <div className="unlock__bordered bordered mx-auto mt-5" style={{ maxWidth: '100%' }}>
      <div className="bordered__inner bordered--flex staking-card">
        <div className="bordered__block p-4 pb-3 p-2-mb" style={{ maxWidth: '100%', border: 0 }}>
          <div className="block-title">
            <h4>Schedule</h4>
          </div>
          <div className="table-responsive">
            <table className="table mb-0 pp-table-info text-white">
              <tbody>
                <tr className="card-header" style={{ border: "none" }}>
                  <td className="text-white">Round</td>
                  <td className="text-white">Opens</td>
                  <td className="text-white">Closes</td>
                </tr>
                {props.roundInfo.length > 0 &&
                  props.roundInfo.map((item, key) =>
                  (
                    <tr key={key}>
                      <td className="text-white">{item.round}</td>
                      <td>
                        <h5 className="mb-0">{moment(item.opens * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                      </td>
                      <td>
                        <h5 className="mb-0">{moment(item.closes * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleTabComponent;
